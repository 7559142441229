import styled from "styled-components";

export const Container = styled.div`
    padding: 5rem 15rem 0 15rem;
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 1rem;

    .item {
        width: 200px;
        background-color: white;
        padding: 1rem 1.4rem;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        transition: all .3s ease;

        &__image {
            width: 25px;
            transition: all .3s ease;
        }
        &__title {
            font-size: 16px;
            color: black;
            font-weight: 500;
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
            .item__image {
                transform: scale(1.1);
            }
        }
    }
`