import './Footer.css'
import TelIcon from '../../assets/home/iconos/012-Telefono.svg'
import CorreoIcon from '../../assets/home/iconos/013-Correo.svg'
import UbicacionIcon from '../../assets/home/iconos/014-Ubicacion.svg'
import Logo from '../../assets/home/iconos/020-Logo-futter.svg'

// social icons
import FacebookIcon from '../../assets/home/iconos/015-FB.svg'
import InstagramIcon from '../../assets/home/iconos/016-IG.svg'
import TikTokIcon from '../../assets/home/iconos/017-TikTok.svg'
import LinkedinIcon from '../../assets/home/iconos/018-Linkedin.svg'
import YoutubeIcon from '../../assets/home/iconos/019-Youtube.svg'

import FormHome from '../form-home/FormHome'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='footer__item'>
                    <div className='footer__left'>
                        <h2>Contáctanos</h2>
                        <div className='footer__icon'>
                            <img src={TelIcon} alt="icono telefono" />
                            <p>(502) 1234-5678</p>
                        </div>
                        <div className='footer__icon'>
                            <img src={CorreoIcon} alt="icono correo" />
                            <p>info@livefree.app</p>
                        </div>
                        <div className='footer__icon'>
                            <img src={UbicacionIcon} alt="icono ubicacion" />
                            <p>Ciudad de Guatemala</p>
                        </div>
                    </div>
                    <div className='footer__center'>
                        <nav>
                            <Link href='/categorias'>Categorías</Link>
                            <a href='/#nuevo'>Nuevo</a>
                            <a href='/#resultados'>Resultados</a>
                            <a href='/#planes'>Planes</a>
                        </nav>
                    </div>
                    <div className='footer__right'>
                        {/* <h2>Suscríbete</h2> */}
                        <FormHome type={'newsletter'} />
                        <div className='footer__icon-container'>
                            <img src={FacebookIcon} alt="icono facebook" />
                            <img src={InstagramIcon} alt="icono instagram" />
                            <img src={TikTokIcon} alt="icono tiktok" />
                            <img src={LinkedinIcon} alt="icono linkedin" />
                            <img src={YoutubeIcon} alt="icono youtube" />
                        </div>
                    </div>
                </div>
                <div className='footer__logo-container'>
                    <img src={Logo} alt="logo livfree" />
                    <p>2023 LivFree. Todos los derechos reservados.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer