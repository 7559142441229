import { Container, Wrapper } from "./StyledPrivateFooter"
// import { Link } from "react-router-dom"

//image
import LogoFooter from '../../assets/home/iconos/020-Logo-futter.png'
import FacebookIcon from '../../assets/home/iconos/015-FB.svg'
import InstagramIcon from '../../assets/home/iconos/016-IG.svg'
import TikTokIcon from '../../assets/home/iconos/017-TikTok.svg'
import LinkedinIcon from '../../assets/home/iconos/018-Linkedin.svg'
import YoutubeIcon from '../../assets/home/iconos/019-Youtube.svg'

const PrivateFooter = () => {
    return (
        <Container>
            <Wrapper>
                <div className="icon">
                    <img src={LogoFooter} alt={`Logo footer`} />
                </div>
                <div className="links">
                    <nav>
                        <a href="/#" >Privacidad</a>
                        <a href="/#" >Términos</a>
                        <a href="/#" >Ayuda</a>
                        <a href="/#" >Dispositivos</a>
                    </nav>
                </div>
                <div className="footer__icons">
                    <img src={FacebookIcon} alt="icono facebook" />
                    <img src={InstagramIcon} alt="icono instagram" />
                    <img src={TikTokIcon} alt="icono tiktok" />
                    <img src={LinkedinIcon} alt="icono linkedin" />
                    <img src={YoutubeIcon} alt="icono youtube" />
                </div>
                <div className="copyright">
                    <p>2023 LivFree. Todos los derechos reservados.</p>
                </div>
            </Wrapper>
        </Container>
    )
}

export default PrivateFooter