import styled from 'styled-components'

export const Container = styled.div`
    background-color: #0B2442;
    padding-bottom: 5rem;
`

export const Section = styled.section`
    margin: ${props => props.center ? '5rem 15rem' : '5rem auto 5rem 15rem'};
    h2 {
        display: flex;
        font-size: 25px;
        color: white;
        margin: 2rem 0;
        align-items: center;
        gap: 1rem;

        img {
            width: 10px;
            margin-top: 5px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .rating{
        color:white;
        font-size:18px;
    }

    .rating-title{
        color:white;
        font-size:4rem;
    }

    .buttons {
        display: flex;
        gap: 1rem;
    }


    .box-starts{
        gap: 0rem;
        img {
            width: 30px;
            
            &:hover {
                cursor: pointer;
            }
        }
    }

    .box-comment {
        background-color: #D9D9D91A !important; 
        padding:2rem;
        border-radius:5px;
    }

    .link-all{
        color:white !important;
        &:hover {
            cursor: pointer;
        }
    }

    .line-vertical{
        border-left: 2px solid #D9D9D91A;
    }

    .title{
        color: white;
    }

    .link-test{
        font-weight: 300;
        text-decoration: underline !important;

        &:hover {
            cursor: pointer;
        }
    }

    .box-intents{
        background-color:white;
        border-radius: 10px;
        padding:2rem;
    }

    .color-blue{
        color: #002444;
    }


    .description{
        color:white;
        font-weight: lighter; 

    }

    .box {
        margin-right: 15rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &__comment {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            gap: 1rem;

            label {
                color: white;
                font-size: 20px;
            }

            textarea {
                height: 200px;
                border-radius: 10px;
                outline: none;
                padding: 1rem;
            }
        }

        &__rate {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            /* align-items: center;
            justify-content: space-between; */
            justify-content: space-between;
            &--title {
                color: white;
                font-size: 20px;
                margin: 0;
                text-align: left;
            }
        }

        &__icons {
            display: flex;
            justify-content: space-evenly;
            gap: 2.5rem;
            img {
                width: 50px;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        button {
            margin: 0 auto;
        }
    }

    .learning {
        max-width: 1200px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        &__box {
            width: 50%;
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            img {
                width: 50px;
            }

            p {
                color: white;
                font-weight: 200;
                font-size: 16px;
            }
        }
    }    
`

export const Button = styled.button`
    padding: .8rem 2rem;
    background-color: ${props => props.primary ? 'transparent' : '#339DFF'};
    color: ${props => props.primary ? 'white' : 'white'};
    border: 1px solid #399DFF;
    font-size: 16px;
    font-weight: bold;
    transition: all .3s ease;
    &:hover {
        cursor: pointer;
    }
`

export const ErrorContainer = styled.div`
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .error-msg {
        font-size: 40px;
        color: white;
        font-weight: bold;
    }
`