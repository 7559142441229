import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { Button, Buttons, Container, QuestionsWrapper, Wrapper } from "./StyledTest"
import axios from '../../api/axios';
import { testsUrl } from '../../api/url';
import Spinner from '../spinner/Spinner';

import { createAttempt } from '../../redux/educationDucks'
import Swal from 'sweetalert2'

const Test = ({ handleCloseTest, showTest }) => {

    const dispatch = useDispatch()
    const { userId } = useSelector(state => state.userInfo)
    
    const [loading, setLoading] = useState(false)
    const [questions, setQuestions] = useState([])

    const [indicePreguntaActual, setIndicePreguntaActual] = useState(0)
    const [puntaje, setPuntaje] = useState(0)

    const [indiceRespuestaSeleccionada, setIndiceRespuestaSeleccionada] = useState(null)
    const [respuestaSeleccionada, setRespuestaSeleccionada] = useState(false)

    const { course } = useParams()
    const [examId, setExamId] = useState(0)
    
    useEffect(() => {

      if(showTest && course && userId){
        const getTestByCourseId = async () => {
          try {
            setLoading(true)
            const { data } = await axios.get(testsUrl.getTestByCourseIdAndUserId(course))
            setQuestions(data.questions)
            setExamId(data.examId);
            setLoading(false)
          } catch (error) {
            console.log(error)
          }
        }
        getTestByCourseId()
      }


    }, [showTest, course, userId])

    

    const handleAnterior = () => {
        setIndicePreguntaActual(( indiceAnterior ) => Math.max(indiceAnterior - 1, 0))
        setIndiceRespuestaSeleccionada(null)
        setRespuestaSeleccionada(false)
    }

    const handleSiguiente =  async () => {
        if(questions.length !== indicePreguntaActual+1){
          
          setIndicePreguntaActual((indiceSiguiente) =>
            Math.min(indiceSiguiente + 1, questions.length - 1)
          );
          setIndiceRespuestaSeleccionada(null)
          setRespuestaSeleccionada(false)
        }else{
          let params = {
            "score":puntaje,
            "examId":examId,
            "userId": userId
          }
          //dispatch(createAttempt({...params, type: 'normal'}))

          const createAtempt = async () => {
            try {
              const { data } = await axios.post(testsUrl.createAttempt, params)              
              
            } catch (error) {
              console.log(error)
            }
          }
          await createAtempt()
          handleCloseTest()
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Tu calificación es de '+puntaje+' puntos',
            showConfirmButton: false,
            timer: 1500
          })
        }
    };
    
    const { pregunta, respuestas } = questions[indicePreguntaActual] || {}

    const handleSelectCorrectAnswer = (index, esCorrecta) => {
      if(esCorrecta){

        console.log(puntaje);

        setPuntaje(puntaje+1);

        console.log(puntaje);
      }
      if(!respuestaSeleccionada){
        setIndiceRespuestaSeleccionada(index)
        setRespuestaSeleccionada(true)
      }
    }

    if(loading) return <div style={{ display: 'flex', minHeight: '100vh', backgroundColor: '#0B2442', justifyContent: 'center', alignItems: 'center' }} ><Spinner /></div>;

    return (
        <Container>
            <Button onClick={() => handleCloseTest()} >Atras</Button>
            <Wrapper>
                <h2 className="title" >Aprendizaje</h2>
                <QuestionsWrapper>
                    <p className='question'>{pregunta}</p>
                    <div className='answer-container'>
                        { questions.length > 0 && respuestas.map((respuesta, index) => (
                            <div key={index} className={`answer ${indiceRespuestaSeleccionada === index ? respuesta.esCorrecta ? 'selected' : 'incorrect' : ''}`} onClick={() => handleSelectCorrectAnswer(index, respuesta.esCorrecta)}  >
                                { respuesta.texto }
                            </div>
                        )) }
                    </div>
                </QuestionsWrapper>
                <Buttons>
                    <Button primary onClick={handleAnterior} >Anterior</Button>
                    <Button onClick={handleSiguiente} >Siguiente</Button>
                </Buttons>
            </Wrapper>
        </Container>     
    )
}

export default Test