import styled from 'styled-components'

export const Container = styled.div`
    background-color: white;
    padding: 5rem 0;
    overflow: hidden;
`

export const Title = styled.h1`
    font-size: 45px;
    color: #5786ff;
    text-align: center;
    margin: 2rem 0;
`
