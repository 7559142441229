import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/home/iconos/00-Logo-menu.svg";
import Logo2 from "../../assets/home/iconos/020-Logo-futter.png";
import "./Navbar.css";
import { useLocation, useNavigate } from "react-router-dom";

// images
import DeudasImage from "../../assets/home/iconos/07-deudas.svg";
import AhorroImage from "../../assets/home/iconos/03-ahorro.svg";
import PresupuestoImage from "../../assets/home/iconos/04-presupuesto.svg";
import LibertadImage from "../../assets/home/iconos/LibertadFinanciera-celeste.svg";
import InversionesImage from "../../assets/home/iconos/06-inversiones.svg";
import IngresosImage from "../../assets/home/iconos/05-ingresos-egresos.svg";

const Navbar = ({ setNavbarHeight }) => {
  const [activeLink, setActiveLink] = useState(null);

  // cambiar clases de menu hamburguesa
  const [burgerClass, setBurgerClass] = useState("burger-bar unclicked");
  const [menuClass, setMenuClass] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  // shrink navbar
    const [isShrink, setIsShrink] = useState(false)

    const isActiveShrink = () => {
        window.scrollY > 0 ? setIsShrink(true) : setIsShrink(false)
    }

    useEffect(() => {
        if (pathname === '/') {
            window.addEventListener('scroll', isActiveShrink)
            return () => {
                window.removeEventListener('scroll', isActiveShrink)
            }
            
        }
    }, [pathname])

   useEffect(() => {
     const updateNavbarHeight = () => {
       isShrink ? setNavbarHeight(75) : setNavbarHeight(130);
     };

     updateNavbarHeight();
   }, [isShrink, setNavbarHeight]);


  // hover categorias
  const [isHoveringCategories, setIsHoveringCategories] = useState(false);

  // hover results
  const [isHoveringResultado, setIsHoveringResultados] = useState(false);


  const menuRef = useRef(null);

  //toggle burger menu
  const updateMenu = () => {
    console.log("click");
    setBurgerClass((prevClass) => (prevClass === "burger-bar clicked" ? "burger-bar unclicked" : "burger-bar clicked"));
    setMenuClass((prevClass) => (prevClass === "menu visible" ? "menu hidden" : "menu visible"));
    setIsMenuClicked((prevClicked) => !prevClicked);
  };
  //Category
  const handleShowCategories = () => {
    setIsHoveringCategories(true);
  };

  const handleHideCategories = () => {
    setIsHoveringCategories(false);
  };
  //Results
  const handleHideResultados = () => {
    setIsHoveringResultados(true);
  };

  const handleShowResultados = () => {
    setIsHoveringResultados(false);
  };

  const handleOutsideClick = (e) => {
    if (
      !menuRef.current.contains(e.target) &&
      !e.target.classList.contains("burger-menu") &&
      !e.target.parentNode.classList.contains("burger-menu")
    ) {
      setBurgerClass("burger-bar unclicked");
      setMenuClass("menu hidden");
      setIsMenuClicked(false);
    }
  };

  // scroll to section
  const scrollToSection = (e, id) => {
    e.preventDefault();
    if (pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementsByName(id)[0];
        if (element) {
          const offsetTop = element.getBoundingClientRect().top;
          const extraOffset = 70;
          window.scrollTo({ top: offsetTop + window.scrollY - extraOffset, behavior: "smooth" });
        }
      }, 500);
    } else {
      const element = document.getElementsByName(id)[0];
      if (element) {
        const offsetTop = element.getBoundingClientRect().top;
        const extraOffset = 70;
        window.scrollTo({ top: offsetTop + window.scrollY - extraOffset, behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (pathname === "/categorias") {
      setActiveLink("categorias");
    }
  }, [pathname]);
    
    useEffect(() => {
        if (pathname === '/testimoniales' || pathname === "/categorias") {
          setIsShrink(true)
      }
  }, [pathname])

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

      return (
        <div style={{ position: "relative" }}>
          <div className={`navbar ${isShrink ? 'shrink' : ''}`}>
            <div className="left">
              <div className="logo">
                <Link to="/" onClick={() => setActiveLink(null)}>
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="center">
              <nav>
                <Link
                  to="/categorias"
                  className={activeLink === "categorias" ? "active" : ""}
                  onClick={() => setActiveLink("categorias")}
                  onMouseEnter={() => handleShowCategories()}
                  onMouseLeave={() => handleHideCategories()}
                >
                  Categorías
                </Link>
                <Link
                  to="/"
                  className={activeLink === "nuevo" ? "active" : ""}
                  onClick={(e) => {
                    setActiveLink("nuevo");
                    scrollToSection(e, "nuevo");
                  }}
                >
                  Nuevo
                </Link>
                <Link
                  to="testimoniales"
                  className={activeLink === "resultados" ? "active" : ""}
                  onClick={() => setActiveLink("resultados")}
                  onMouseEnter={() => handleShowResultados()}
                  onMouseLeave={() => handleHideResultados()}
                >
                  Resultados
                </Link>
                <Link
                  to="/"
                  className={activeLink === "planes" ? "active" : ""}
                  onClick={(e) => {
                    setActiveLink("planes");
                    scrollToSection(e, "planes");
                  }}
                >
                  Planes
                </Link>
              </nav>
              <div className={`burger-menu ${isShrink ? 'shrink' : ''}`} onClick={updateMenu}>
                <div className={burgerClass}></div>
                <div className={burgerClass}></div>
                <div className={burgerClass}></div>
              </div>
              <div className={menuClass} ref={menuRef}>
                <div className="movil-menu">
                  <div className="logo-2">
                    <img src={Logo2} alt="Logo livfree" />
                  </div>
                  <div className="nav-movil">
                    <nav>
                      <Link to="/categorias">Categorías</Link>
                      <a
                        href="#nuevo"
                        onClick={(e) => {
                          setActiveLink("nuevo");
                          scrollToSection(e, "nuevo");
                        }}
                      >
                        Nuevo
                      </a>
                      <Link to="testimoniales">Resultados</Link>
                      <a
                        href="#planes"
                        onClick={(e) => {
                          setActiveLink("nuevo");
                          scrollToSection(e, "planes");
                        }}
                      >
                        Planes
                      </a>
                      <Link to="/login" className="different">
                        Login
                      </Link>
                      <Link to="/register" className="different">
                        Registro
                      </Link>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="contenedor-botones">
                <Link to="/login">
                  <button className="boton-login">Iniciar sesión</button>
                </Link>
                <Link to="/register">
                  <button className="boton-registro">Crear cuenta</button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`hover-menu ${isShrink ? 'shrink' : ''}`}
            style={{ visibility: isHoveringCategories ? "visible" : "hidden" }}
            onMouseEnter={() => handleShowCategories()}
            onMouseLeave={() => handleHideCategories()}
          >
            <div className="contenedor-iconos" style={{ visibility: isHoveringCategories ? "visible" : "hidden" }}>
              <Link to="/categorias?cat=ahorro">
                Ahorro
                <img src={AhorroImage} alt="Icono ahorro" />
              </Link>
              <Link to="/categorias?cat=presupuestos">
                Presupuestos
                <img src={PresupuestoImage} alt="Icono presupuesto" />
              </Link>
              <Link to="/categorias?cat=ingresos y egresos">
                Ingresos y egresos
                <img src={IngresosImage} alt="Icono ingresos-egresos" />
              </Link>
              <Link to="/categorias?cat=inversiones">
                Inversiones
                <img src={InversionesImage} alt="Icono inversiones" />
              </Link>
              <Link to="/categorias?cat=deudas">
                Deudas
                <img src={DeudasImage} alt="Icono deudas" />
              </Link>
              <Link to="/categorias">
                Libertad Financiera
                <img src={LibertadImage} alt="Icono libertad" />
              </Link>
            </div>
          </div>
        </div>
      );

};

export default Navbar;
