import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/free-mode'
import 'swiper/swiper-bundle.css'

import { Pagination, Autoplay, Navigation } from 'swiper'

import { Container, Wrapper } from './StyledSwiperTestimonial'

const SwiperTestimonial = ({testimonials, setActiveIndex, activeIndex}) => {

    return (
        <Wrapper>
            { testimonials.length > 0 && <Swiper
                slidesPerView={6}
                spaceBetween={20}
                pagination={{
                    el: '.pagination',
                    // clickable: true
                }}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true
                }}
                initialSlide={testimonials.length}
                centeredSlides
                loop
                modules={[Pagination, Autoplay, Navigation]}
                className='testimonial-swiper'
                navigation={true}
                onSlideChange={swiper => {
                    setActiveIndex(swiper.realIndex + 1)
                }}
                breakpoints={{
                    "@0.00": {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    "@0.25": {
                        slidesPerView: 2.25,
                        spaceBetween: 10,
                    },
                    "@0.50": {
                        slidesPerView: 2.25,
                        spaceBetween: 25,
                    },
                    "@1.00": {
                        slidesPerView: 5.25,
                        spaceBetween: 25,
                    },
                    "@1.25": {
                        slidesPerView: 3.5,
                        spaceBetween: 20,
                    },
                    "@1.50": {
                        slidesPerView: 5.25,
                        spaceBetween: 30,
                    },
                    "@1.65": {
                        slidesPerView: 5.5,
                        spaceBetween: 30
                    },
                    "@1.75": {
                        slidesPerView: 5.5,
                        spaceBetween: 20,
                    },
                    "@1.85": {
                        slidesPerView: 6,
                        spaceBetween: 20
                    },
                }}
            >
                { testimonials.map((item, i) => (
                    <SwiperSlide key={item.id}>
                        <Container active={(i + 1) === activeIndex} >
                            <img src={item.photo} alt="imagen prueba" />
                            <div className='info'>
                                <p>{`${item.name} ${item.lastname}`}</p>
                                <p className='ocupation'>{item.position}</p>
                            </div>
                        </Container>
                    </SwiperSlide>
                )) }
            </Swiper>
            }
            </Wrapper>
    )
}

export default SwiperTestimonial