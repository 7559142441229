import AhorroImg from '../../assets/home/iconos/03-ahorro.svg'
import PresupuestoImg from '../../assets/home/iconos/04-presupuesto.svg'
import IngresosEgresosImg from '../../assets/home/iconos/05-ingresos-egresos.svg'
import InversionesImg from '../../assets/home/iconos/06-inversiones.svg'
import DeudasImg from '../../assets/home/iconos/07-deudas.svg'
import Card from '../card/Card'


import './Cards.css'

const cardsInfo = [
    {
        id: 1,
        img: AhorroImg,
        info: 'Ahorro'
    },
    {
        id: 2,
        img: PresupuestoImg,
        info: 'Presupuestos'
    },
    {
        id: 3,
        img: IngresosEgresosImg,
        info: 'Ingresos y egresos'
    },
    {
        id: 4,
        img: InversionesImg,
        info: 'Inversiones'
    },
    {
        id: 5,
        img: DeudasImg,
        info: 'Deudas'
    }
    
]

const Cards = () => {
    return (
        <div className='cards-container'>
            { cardsInfo.map(card => (
                <Card 
                    key={card.id}
                    { ...card }
                />
            )) }
        </div>
    )
}

export default Cards