import styled from 'styled-components'
//image
import BannerImg from '../../assets/curso/imagenes/00-Portada-Ahorro.jpg'

export const Container = styled.div`
    height: 600px;
    width: 100%;
    /* position: relative; */
    background-image: url(${BannerImg});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`

export const Wrapper = styled.div`
    padding: 5rem 15rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
    align-items: center;

    .course {
        /* position: absolute; */
        bottom: 0;
        left: 50px;
        color: white;
        grid-column: 1 / 2;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        &__category {
            color: #339dff;
            font-size: 30px;
            text-transform: uppercase;
            letter-spacing: 5px;
            margin: 0;
        }

        &__title {
            font-size: 40px;
            line-height: 1;
            font-weight: 600;
            margin: 0;
        }

        &__author {
            color: #339DFF;
            font-size: 20px;
            margin: 0;
        }

        &__info {
            font-size: 15px;
            line-height: 1;
            font-weight: 200;
        }
    }

`