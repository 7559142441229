import './UserCard.css'

const UserCard = ({user}) => {
    return (
     

        <div className="card mt-3 mb-3">
            <img src={user.img} alt={user.id}/>
            <div className="overlay row m-0">
                <div className="text-center w-100">
                    <p className="w-100">{user.type}</p>
                    <h3 className="w-100">{user.name}</h3>
                    <p className="w-100">{user.title}</p>
                </div>
            </div>
        </div>
     
    )
}

export default UserCard