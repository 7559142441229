import { memo } from 'react'

import './TestimonialItem.css'

const TestimonialItem = ({ id, description, photo, name, lastname, position, country }) => {

    return (
        <div className='testimonial-item'>
            <div className='testimonial-item__left'>
                <img src={photo} alt={`img${id}`} />
            </div>
            <div className='testimonial-item__right'>
                <p className='testimonial-item__description'>
                    "{ description }"
                </p>
                <p className='testimonial-item__name'>
                    <span>{name} {lastname}</span> - {position}, { country }
                </p>
            </div>
        </div>
    )
}

export default memo(TestimonialItem)