import { Outlet } from 'react-router-dom'
import PrivateFooter from '../components/footer/PrivateFooter'
import PrivateNavbar from '../components/navbar/PrivateNavbar'

const PrivateLayout = () => {
    return (
        <div>
            <PrivateNavbar />
            <Outlet />
            <PrivateFooter />
        </div>
    )
}

export default PrivateLayout