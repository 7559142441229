import styled from 'styled-components'

export const Item = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    .principal-image {
        border-radius: 20px;
        object-fit: cover;
        @media(min-width: 768px){
            height: 130px;
        }
        @media(min-width: 1024px){
            height: 175px;
        }
        @media(min-width: 1800px){
            height: 220px;
        }
    }

    .icon {
        padding: 10px 15px;
        background-color: white;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        border-radius: 10px;
        bottom: 20px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);

        &__image {
            width: 25px;
        }
        &__title {
            font-size: 14px;
            margin: 0;
        }
    }
`