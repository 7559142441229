import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';

// asegúrate de llamar a esto para habilitar el manejo de accesibilidad
Modal.setAppElement('#root');

const ShareModal = ({ isOpen, onRequestClose }) => {
  // obtén la información del curso del estado global
  const {course} = useSelector(state => state.courses);

  const customStyles = {
    overlay: {
        position: 'fixed',
        zIndex: 1020,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(255, 255, 255, 0.75)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        background: 'white',
        width: '45rem',
        maxWidth: 'calc(100vw - 2rem)',
        maxHeight: 'calc(100vh - 2rem)',
        overflowY: 'auto',
        position: 'relative',
        border: '1px solid #ccc',
        borderRadius: '0.5rem',
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2
      }
  };

  const buttonStyles = {
      border: 'none',
      padding: '10px 10px',
      backgroundColor: '#5786FF',
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      borderRadius: '5px',
      fontSize: '10px'
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Compartir curso"
      style={customStyles}
    >
      <h2 style={{ margin: 0, textTransform: 'uppercase' }} >{course.title}</h2>
      <p>{course.description}</p>

      <div style={{ display: 'flex', gap: '1rem' }} >
        <FacebookShareButton
            // url={course.url}
            quote={course.title}
            hashtag="#myAwesomeCourse"
        >
            <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton
            // url={course.url}
            title={course.title}
            hashtags={['myAwesomeCourse']}
        >
            <TwitterIcon size={32} round />
        </TwitterShareButton>
        <WhatsappShareButton
            // url={course.url}
            title={course.title}
            hashtags={['myAwesomeCourse']}
        >
            <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>


      <button onClick={onRequestClose} style={buttonStyles} >Cerrar</button>
    </Modal>
  );
};

export default ShareModal;