import { useState, useMemo } from 'react'
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

//images
import TestingImage from '../../assets/private-home/imagenes/00-Banner-1.jpg'
import { Item } from './StyledPrivatedCategorySlider'

// ahorro-example
import DeudasIcon from '../../assets/home/iconos/07-deudas.svg'
import AhorroIcon from '../../assets/home/iconos/03-ahorro.svg'
import PresupuestoIcon from '../../assets/home/iconos/04-presupuesto.svg'
import LibertadIcon from '../../assets/home/iconos/LibertadFinanciera-celeste.svg'
import InversionesIcon from '../../assets/home/iconos/06-inversiones.svg'
import IngresosIcon from '../../assets/home/iconos/05-ingresos-egresos.svg'



import AhorroImage from '../../assets/banners/banner-prueba3.jpg'
import inversionImage from '../../assets/banners/banner-prueba.jpg'
import deudasImage from '../../assets/banners/banner-prueba5.jpg'
import ingresosImage from '../../assets/banners/banner-prueba4.jpg'
import prespuestoImage from '../../assets/banners/banner-prueba2.jpg'


const PrivateCategorySlider = ({ privated = null }) => {

    const [testArray, setTestArray] = useState(Array(5).fill({ image: TestingImage }))

    const navigate = useNavigate()

    const array = useMemo(() => [
      {
        image: AhorroImage, title: 'Ahorro', icon: AhorroIcon, id: 1
      },
      {
        image: prespuestoImage, title: 'Presupuesto Personal', icon: PresupuestoIcon, id: 2
      },
      // {
      //   image: AhorroImage, title: 'Libertad Financiera', icon: LibertadIcon, id: 3
      // },
      {
        image: inversionImage, title: 'Inversiones', icon: InversionesIcon
      },
      {
        image: deudasImage, title: 'Deudas', icon: DeudasIcon
      },
      {
        image: ingresosImage, title: 'Ingresos', icon: IngresosIcon
      },
    ], []);
    
    const {category} = useParams()

    const initialSlide = useMemo(() => {
        if(category){
            const index = array.findIndex(item => item.title.toLowerCase() === category.toLowerCase())
            return index >= 0 ? index : 0 // Si el índice es -1, entonces no se encontró una categoría coincidente, así que se usa 0 como índice predeterminado.
        }
        return 0 // Si no hay parámetro category, entonces se usa 0 como índice predeterminado.
    }, [category, array])

    useEffect(() => {
    
      if(category){
        const handleArray = () => {
            setTestArray(array)
          }
        handleArray()
      }

    }, [category, array])

    const handleSlideChange = swiper => {
      let slideIndex = swiper.activeIndex - 3;
      if(slideIndex === -1){
        slideIndex = 5;
      }
      if(slideIndex > 5){
        slideIndex = 0;
      }
      // const slideIndex = swiper.activeIndex - 3 > 5 ? 0 : swiper.activeIndex - 3
      const slideCategory = array[slideIndex].title.toLocaleLowerCase()
      navigate(`/inicio/categorias/${slideCategory}`)
    }

    const handleTest = () => {
      console.log('test')
    }
    
    return (
        <>
          <Swiper
            modules={[Pagination, Navigation]}
            centeredSlides={true}
            slidesPerView={3}
            spaceBetween={30}
            navigation={true}
            pagination={{
              clickable: true
            }}
            loop={true}
            className='swiper-categories-container'
            initialSlide={initialSlide}
            onSlideChange={privated ? handleSlideChange : handleTest}
          >
            { array.map(( item,i ) => (
              <SwiperSlide
                key={i}
              >
                <Item>
                  <img className='principal-image' src={item.image} alt="Imagen prueba" />
                  { category && (
                    <div className='icon'>
                      <p className='icon__title' >{item.title}</p>
                      <img src={item.icon} alt={`icon`} className='icon__image' />
                    </div>
                  ) }
                </Item>  
              </SwiperSlide>
            )) }
          </Swiper>
        </>  
    )
}

export default PrivateCategorySlider