import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Spinner from '../spinner/Spinner';
import styled from 'styled-components'
import { useDispatch } from 'react-redux';
import axios from '../../api/axios';

const SuccessMessage = styled.p`
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 10px 0;
  text-align: center;
  letter-spacing: 1px;
  color: #0b2442;
`

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const CheckoutForm = ({ mail }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [cardOwner, setCardOwner] = useState('');

  const dispatch = useDispatch()

  const handleSubmit = async (event) => {
    event.preventDefault();

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardOwner // Set the card owner's name
      }
    });

    if (!error) {
      const { id } = paymentMethod;

      try {
        setLoading(true)
        // const response = await fetch('http://127.0.0.1:3000/api/payment/subscription-payment', {
        //   method: 'POST',
        //   headers: {
        //     "Content-Type": "application/json"
        //   },
        //   body: JSON.stringify({
        //     email: mail,
        //     payment_method: id,
        //     planId: 'price_1NVetQFLbR6yfUrbxFokytEh'
        //   })
        // });

        const response = await axios.post('/payment/subscription-payment', {
          email: mail,
          payment_method: id,
          planId: 'price_1NVetQFLbR6yfUrbxFokytEh'
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });


        const data = response.data;
        setLoading(false)

        if (data.status) {
          setSuccessMessage('Pago realizado con éxito')
          setTimeout(() => {
            dispatch({
              type: 'ACTIVATE_MEMBERSHIP'
            })
          }, 1500)
          return;
        }

        setSuccessMessage(data.message)

      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCardOwnerChange = (event) => {
    setCardOwner(event.target.value);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
      backgroundColor: '#f0f0f0',
      padding: '50px',
      boxSizing: 'border-box'
    }}>
      <div style={{
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        padding: '30px',
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: '400px'
      }}>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '20px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>Nombre del propietario de la tarjeta</label>
            <input
              type="text"
              value={cardOwner}
              onChange={handleCardOwnerChange}
              style={{
                width: '100%',
                padding: '10px',
                boxSizing: 'border-box',
                border: '1px solid #ccc',
                borderRadius: '5px',
                fontSize: '16px'
              }}
            />
          </div>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          <button type="submit" disabled={!stripe} style={{
            marginTop: '30px',
            width: '100%',
            padding: '10px',
            boxSizing: 'border-box',
            border: 'none',
            background: 'rgb(57, 157, 223)',
            color: '#ffffff',
            borderRadius: '5px',
            cursor: 'pointer'
          }}>
            Pagar
          </button>
          {
            successMessage && <SuccessMessage>{successMessage}</SuccessMessage>
          }
            {
                loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <Spinner />
                    </div>
                )
            }
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;