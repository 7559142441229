import React from "react";
import styled from "styled-components"

const AlertContainer = styled.div`
    color: ${props => props.error ? 'red' : '#5786ff'};
    font-size: 13px;
    font-weight: 200;

    span {
        font-weight: 800;
    }
`

const Alert = ({ alert }) => {
    const words = alert.msg.split(" ");
  
    return (
        <AlertContainer error={alert.error}>
            {words.map((word, index) => (
                <React.Fragment key={index}>
                    {word.includes("@") ? <span>{word}</span> : word}
                    {index < words.length - 1 && " "}
                </React.Fragment>
            ))}
        </AlertContainer>
    )
}

export default Alert