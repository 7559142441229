import SwiperTestimonial from "../../components/swiper-testimonial/SwiperTestimonial"
import { Container, Title } from "./StyledTestimonials"
import Slider from "react-slick"
import { useEffect, useState, useRef } from 'react'
// images 
import Testimonial1 from '../../assets/home/imagenes/08-Testimonial-1.jpg'
import Testimonial2 from '../../assets/home/imagenes/09-Testimonial-2.jpg'
import TestimonialItem from "../../components/testimonial_item/TestimonialItem"
import '../../components/testimonial/Testimonial.css'
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllTestimonials } from "../../redux/testimonialDucks"
import Spinner from "../../components/spinner/Spinner"

const Testimonials = () => {

    const dispatch = useDispatch()
    const { 
        testimonials, 
        loading 
    } = useSelector(state => state.testimonial)
    const [activeIndex, setActiveIndex] = useState(1)


    const sliderRef = useRef()

    console.log(activeIndex)

    useEffect(() => {
        dispatch(getAllTestimonials())
    }, [dispatch])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])


    const handleSlideChange = currentSlide => {
        console.log('slider cambió', currentSlide + 1)
    }

    useEffect(() => {
        if(sliderRef.current){
            sliderRef.current.slickGoTo(activeIndex - 1)
        }
    }, [activeIndex])

    const settings = useMemo(() => {
        return {
            // dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            afterChange: handleSlideChange,
            swipe: false
        }
    }, [])

    const styles = useMemo(() => {
        return {
            maxWidth: '1200px',
            width: '90%',
            margin: '150px auto',
        }
    }, [])

    return (
        <Container>
            <Title>Casos de éxito</Title>
            { !loading && testimonials.length > 0 ? 
            <>
                <SwiperTestimonial testimonials={testimonials} setActiveIndex={setActiveIndex} activeIndex={activeIndex} />
                <div style={styles} >
                    <Slider
                        { ...settings }
                        ref={sliderRef}
                    >
                        { testimonials.map(testimonial => (
                            <div key={testimonial.id}>
                                <TestimonialItem 
                                    { ...testimonial }
                                />
                            </div>
                        )) }
                    </Slider>
                </div>
            </>
            : 
                <div style={{ minHeight: '20vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spinner />
                </div>
            }
        </Container>
    )
}

export default Testimonials