import styled from 'styled-components'

// image
import LearningImage from '../../assets/curso/iconos/07-aprendizaje.svg'

export const Container = styled.div`
    background-color: #0B2442;  
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;

    .title {
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;

        &::before {
            content: '';
            width: 40px;
            height: 40px;
            display: block;
            background-image: url(${LearningImage});
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
`

export const QuestionsWrapper = styled.div`

    .question {
        color: white;
        text-align: center;
        font-size: 25px;
    }

    .answer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    .answer {
        padding: 1rem;
        border: 1px solid white;
        color: white;
        width: 400px;
        font-weight: 100;
        border-radius: 20px;
        transition: all .3s ease;

        &:hover {
            background-color: white;
            cursor: pointer;
            color: #5786ff;
            font-weight: 300;
        }

        &.selected {
            background-color: white;
            color: #5786ff;
            font-weight: 300;
        }
        &.incorrect {
            background-color: red;
            color: white;
            font-weight: 300;
        }
    }
`

export const Buttons = styled.div`
    width: 60%;
    display: flex;
    justify-content: space-between;
`

export const Button = styled.button`
    padding: .5rem 1.5rem;
    border: none;
    background-color: ${props => props.primary ? 'transparent' : '#339DFF'};
    color: white;
    font-weight: 700;
    border: 1px solid #339dff;
    &:hover {
        cursor: pointer;
    }
`

