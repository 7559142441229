import { useEffect, useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
// import { getCoursesByCategory } from '../redux/educationDucks'
import { fetchDataByCategory } from "../redux/educationDucks"

// images
import Expert1 from '../assets/private-home/imagenes/04-Expert-1.png'

//components
import CategorySlider from "../components/CategorySlider"
import PrivateSwiper from "../components/private-swiper/PrivateSwiper"
import { useLocation } from "react-router-dom"

const Container = styled.section`
    background-color: #0B2442;
    /* display: block; */
    padding-bottom: 5rem;
`

const Section = styled.section`
    margin: 4rem 1rem 4rem 1rem;
    @media (min-width: 600px){
        margin: 4rem 2rem 4rem 2rem;
    }
    @media (min-width: 992px){
        margin: 4rem auto 4rem 15rem;

    }
    h2 {
        font-size: 25px;
        color: white;
        margin: 2rem 0;
    }
`

const Categorias = () => {

    const [temporaryAuthors, setTemporaryAuthors] = useState([])
    const [videosPopularCourse, setVideosPopularCourse] = useState([])

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const queryCategory = searchParams.get('cat')

    const dispatch = useDispatch()

    const { category, recentlyAdded, popularCourse, coursesUpComing, authors, loading } = useSelector(state => state.courses)
    console.log(category)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        if(Object.keys(category || {}).length > 0){
            console.log('existe una categoria')
            console.log(category.id)
            dispatch(fetchDataByCategory(category.id))
        }
    }, [category, dispatch])

    useEffect(() => {
        if(!loading && authors){
            const newAuthors = authors.map(author => {
                return {
                    ...author,
                    cover_image: Expert1,
                    title: `${author.name} ${author.lastname}`
                }
            })
            setTemporaryAuthors(newAuthors)
        }
    }, [loading, authors])

    useEffect(() => {
        if(!loading && popularCourse){
            const newItems = popularCourse.videoContents?.map(item => {
                return {
                    id: popularCourse.id,
                    thumbnail: item.thumbnail,
                    title: item.title,
                    url: item.url,
                    viewCount: item.viewCount
                }
            })
            setVideosPopularCourse(newItems)
        }
    }, [loading, popularCourse])
    
    return (
        <Container>
            <CategorySlider  queryCategory={queryCategory} />
            <Section>
                <h2>Recién agregados</h2>
                <PrivateSwiper 
                    data={recentlyAdded}
                    play
                />
            </Section>    
            <Section>
                { !loading ? <h2>{popularCourse.title}</h2> : <h2>Cargando...</h2>}
                <PrivateSwiper 
                    data={videosPopularCourse}
                    over={true}
                />
            </Section>    
            <Section>
                <h2>Explora a los expertos</h2>
                <PrivateSwiper 
                    data={temporaryAuthors}
                    circle
                />
            </Section>
            <Section>
                <h2>Próximamente</h2>
                <PrivateSwiper 
                    data={coursesUpComing}
                    comingSoon={true}
                />
            </Section>
        </Container>
    )
}

export default Categorias