import { useEffect, useState } from "react"
import { Container, Left, Right, Top, Section } from "./StyledPrivatedAuthor"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// image
import { getDataByAuthorId } from "../../../redux/authorDucks"
import Spinner from "../../../components/spinner/Spinner"
import PrivateSwiper from "../../../components/private-swiper/PrivateSwiper"


const PrivateAuthor = () => {

    const [localCourses, setLocalCourses] = useState([])
    const [videosRandomCourse, setVideosRandomCourse] = useState([])
    const [localAuthors, setLocalAuthors] = useState([])

    const { id } = useParams()


    const { loading, courses, randomCourse, author, authors, upcomingCourses } = useSelector(state => state.author)
    
    const dispatch = useDispatch()
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        const getData = () => {
            dispatch(getDataByAuthorId(id))
        }
        getData()
    }, [id, dispatch])  

    useEffect(() => {
        if(!loading && courses) {
            const newItems = courses.map(course => ({
                id: course.id,
                title: course.title,
                cover_image: course.cover_image,
                category: course.educationCategory.name
            }))
            setLocalCourses(newItems)
        }
    }, [loading, courses])

    useEffect(() => {
        if(!loading && randomCourse) {
            const newItems = randomCourse.videoContents?.map(course => ({
                id: randomCourse.id,
                thumbnail: course.thumbnail,
                title: course.title,
                url: course.url, 
                category: randomCourse.educationCategory.name
            }))
            setVideosRandomCourse(newItems)
        }
    }, [loading, randomCourse])

    useEffect(() => {
        if(!loading && authors) {
            const newItems = authors.map(author => ({
                ...author,
                cover_image: author.photo,
                title: `${author.name} ${author.lastname}`,
            }))
            setLocalAuthors(newItems)
        }
    }, [loading, authors])


 
    return (
        <Container>
            { loading ? <div style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}} ><Spinner /></div> : 
            <>
                <Top>
                    <Left>
                        <div className="instructor" >
                            <h2>INSTRUCTOR</h2>
                            <p className="instructor__name">{`${author.name} ${author.lastname}`}</p>
                            {/* <p className="instructor__category" >Especialista en finanzas</p> */}
                            <p className="instructor__category" >{author.email}</p>
                        </div>
                        <div className="about">
                            <p className="about__title" >Acerca de mí</p>
                            <p className="about__description" >{author.description}</p>
                        </div>
                    </Left>
                    <Right>
                        <div className="image-website">
                            <img src={author.photo} alt="Imagen de prueba" />
                            <button>Página web</button>
                        </div>
                    </Right>
                </Top>
                <Section>
                    <h2>Mis cursos</h2>
                    <PrivateSwiper 
                        data={localCourses}
                    />
                </Section>
                <Section>
                    <h2>{ randomCourse.title }</h2>
                    <PrivateSwiper 
                        data={videosRandomCourse}
                    />
                </Section>
                <Section>
                    <h2>Explora a otros expertos</h2>
                    <PrivateSwiper 
                        data={localAuthors}
                        circle
                    />
                </Section>
                <Section>
                    <h2>Próximamente</h2>
                    <PrivateSwiper 
                        data={upcomingCourses}
                        comingSoon={true}
                    />
                </Section>
            </>
            }
        </Container>
    )
}

export default PrivateAuthor