import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

//swiper
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Item } from "./StyledPrivateSwiper";
import { useNavigate } from "react-router-dom";

import { markVideoAsWatched , createLastVisited} from "../../redux/educationDucks";

// modal
import Modal from "react-modal";
import ReactPlayer from "react-player";

//icon
import IconPlay from "../../assets/private-home/iconos/09-play.svg";

//skeleton
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";





const PrivateSwiper = ({
  data = [],
  circle = false,
  play = false,
  percentage = false,
  over = false,
  comingSoon = false,
  readyToPlay = false,
  activeFirstVideo = false,
  setActiveFirstVideo = null,
}) => {
  const { loading, category } = useSelector((state) => state.courses);
  const { loading: loadingAuthor } = useSelector((state) => state.author);

  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoTitle, setSelectedVideoTitle] = useState("");
  const [appElement, setAppElement] = useState(null);
  const [isHovering, setIsHovering] = useState(false);

  // porcentaje de video visto
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    setAppElement(document.getElementById("root"));
  }, []);

  const splitText = (text) => {
    if (text.length > 25) {
      return text.slice(0, 25) + "...";
    } else {
      return text;
    }
  };

  const handleClickVideo = (item) => {
    if (comingSoon) {
      return;
    } else {
      if(play){
        
        navigate(`/inicio/course/21/video/1`);
      }else if (circle) {
        navigate(`/inicio/autor/${item.id}`);
      } else {
        //colocar lastvisited
        dispatch(createLastVisited(item.id)).then(() => {
          navigate(
            `/inicio/categorias/${item.category ? item.category?.toLowerCase() : category.name?.toLowerCase()}/${item.id}`
          );
        })
       
      }
    }
  };

  const playVideo = useCallback(
    (item) => {

      dispatch(markVideoAsWatched(item.id, 0)).then(() => {
        navigate(`/inicio/course/${item.educationItem.id}/video/${item.id}`);
      })

    },
  );

  useEffect(() => {
    if (activeFirstVideo) {
      playVideo(data[0]);
    }
  }, [activeFirstVideo, data, playVideo]);

  const modalStyles = {
    overlay: {
      zIndex: 9999,
    },
    content: {
      zIndex: 9999,
    },
  };

  return (
    <>
      <Swiper
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={data?.length >= 6}
        pagination={{
          el: ".pagination",
          clickable: true,
        }}
        slidesPerView={5}
        breakpoints={{
          "@0.00": {
            slidesPerView: circle ? 2 : 1.75,
            spaceBetween: 20,
          },
          "@0.50": {
            slidesPerView: circle ? 2 : 2.25,
            spaceBetween: 25,
          },
          "@1.00": {
            slidesPerView: circle ? 2.25 : 2,
            spaceBetween: 25,
          },
          "@1.25": {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
          "@1.50": {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          "@1.65": {
            slidesPerView: 4.5,
            spaceBetween: 30,
          },
          "@1.75": {
            slidesPerView: 4.5,
            spaceBetween: 20,
          },
          "@1.85": {
            slidesPerView: 5.5,
            spaceBetween: 20,
          },
        }}
      >
        {loading && loadingAuthor
          ? [...Array(8)].map((_, index) => (
              <SwiperSlide key={index}>
                <Skeleton height={165} circle={circle} width={circle && 165} borderRadius={20} />
              </SwiperSlide>
            ))
          : data?.map((item, i) => (
              <SwiperSlide key={i} className="my-slide">
                <Item
                  circle={circle}
                  title={item.title}
                  percentage={item.percentage}
                  comingSoon={comingSoon}
                  onClick={readyToPlay ? () => playVideo(item) : () => handleClickVideo(item)}
                >
                  <div className="image-container">
                    <img
                      src={item.cover_image ? item.cover_image : item.thumbnail}
                      alt={`Imagen ${item.id}`}
                      className="image"
                      onError={(e) => {
                        e.target.src =
                          "https://t2.uc.ltmcdn.com/es/posts/2/1/6/como_ahorrar_dinero_en_casa_51612_orig.jpg";
                      }}
                    />
                    {percentage && (
                      <div className="percentage">
                        <div className="bar"></div>
                      </div>
                    )}
                  </div>
                  {item.title && (
                    <p className={`${over ? "over" : ""} ${circle ? "normal" : ""}`}>{splitText(item.title)}</p>
                  )}
                  {play && <img className="play-icon" src={IconPlay} alt={`icono play`} />}
                </Item>
              </SwiperSlide>
            ))}
      </Swiper>
      {data.length === 0 && (!loading || !loadingAuthor) && <p className="no-data">NO HAY DATOS</p>}
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false);
          setActiveFirstVideo(false);
        }}
        contentLabel="Video"
        style={modalStyles}
        appElement={appElement}
      >
        <div className="video-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <ReactPlayer
            url={selectedVideo}
            playing={modalOpen}
            controls
            width="100%"
            height="100%"
            style={{ position: "absolute", top: 0, left: 0 }}
            onProgress={({ playedSeconds, duration }) => {
              if (playedSeconds && duration) {
                const percent = (playedSeconds / duration) * 100;
                setProgress(percent);
                console.log(progress);
              }
            }}
          />
          {isHovering && (
            <p style={{ display: "block" }} className="video-title">
              {selectedVideoTitle}
            </p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PrivateSwiper;
