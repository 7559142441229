import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../api/axios'
import { activateSevenDayTrial, activateDualMembership } from '../../redux/userDucks'
import './FormHome.css'
import { subscriptionUrl } from '../../api/url'

import { useNavigate, useLocation } from 'react-router-dom'
import Spinner from '../spinner/Spinner'

const FormHome = ({ type = null, buttonValue = 'Suscríbete' }) => {

    const [value, setValue] = useState('')
    const [activated, setActivated] = useState(false)
    const [loadingNewsLetter, setLoadingNewsLetter] = useState(false)
    const [apiMessage, setApiMessage] = useState('')

    const { pathname } = useLocation()
    // newsletter loading

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.userInfo)

    // const isValidEmail = email => {
    //     const regularExpression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     return regularExpression.test(email)
    // }

    const handleSubmit = async e => {
        e.preventDefault()
        // if(value === '' || !isValidEmail(value)){
        //     return
        // }

        if(pathname === '/categorias'){
            setActivated(true)
            dispatch(activateDualMembership())
            return
        }

        if(type === 'trial'){
            setActivated(true)
            dispatch(activateSevenDayTrial())
        } else {
            setLoadingNewsLetter(true)
            const { data } = await axios.post(subscriptionUrl.createSubscription, { email: value })
            setLoadingNewsLetter(false)
            setApiMessage(data.message)
        }
    }

    useEffect(() => {
        if(activated && !loading && type === 'trial'){
            console.log('here')
            navigate('/register')
            setActivated(false)
        } 
    }, [activated, loading, navigate, type])

    useEffect(() => {
        if(pathname === '/categorias' && activated && !loading && !type){
            navigate('/register')
            setActivated(false)
        }
    }, [pathname, activated, loading, navigate, type])

    return ( 
        <div className="form-container">
            <form className="home-form" onSubmit={handleSubmit} >
                <input type={'text'} placeholder="Ingresar correo" onChange={e => setValue(e.target.value)} className={`${type === 'trial' && 'invisible'}`}/>
                <input type={'submit'} value={buttonValue} disabled={loadingNewsLetter} />
            </form>

            { (loadingNewsLetter || (loading && activated && pathname === '/categorias')) && 
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Spinner /> 
                </div>
            }
            { (!loadingNewsLetter && !!apiMessage) && <p>{apiMessage}</p> }
        </div>
    )
}

export default FormHome