import axios from "../api/axios"
import { authorUrl, videoUrl } from "../api/url"

const initialState = {
    author: {},
    courses: [],
    randomCourse: {},
    recentCourses: [],
    upcomingCourses: [],
    loading: false,
    successMessage: null,
    errorMessage: null,
    authors: []
}

//types
const START_LOADING = 'START_LOADING'
const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE'

//reducer
export default function reducer(state = initialState, action = {}) {
    switch(action.type){

        case START_LOADING: 
            return {
                ...state,
                loading: true
            }

        case FETCH_DATA_SUCCESS: {
            return {
                ...state,
                author: action.payload.data1,
                courses: action.payload.data2,
                randomCourse: action.payload.data3,
                authors: action.payload.data4,
                upcomingCourses: action.payload.data5,
                loading: false,
                errorMessage: null
            }
        }

        case FETCH_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
                errorMessage: 'Error al obtener los datos'
            }
        }

        default: 
            return state
    }
}

//actions
export const getDataByAuthorId = id => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const [response1, response2, response3, response4, response5] = await Promise.all([
                axios.get(videoUrl.getAuthorInfo(id)),
                axios.get(videoUrl.getAllCoursesByAuthor(id)),
                axios.get(videoUrl.getRandomCourseByAuthor(id)),
                axios.get(authorUrl.getAllAuthors),
                axios.get(videoUrl.getUnavailableCoursesByAuthor(id))
            ])

            const data1 = response1.data.data
            const data2 = response2.data.data
            const data3 = response3.data.data
            const data4 = response4.data.data
            const data5 = response5.data.data

            dispatch({
                type: FETCH_DATA_SUCCESS,
                payload: { data1, data2, data3, data4, data5 }
            })

        } catch (error) {
            console.log(error)
            dispatch({
                type: FETCH_DATA_FAILURE,
                payload: 'Error al obtener los datos'
            })
        }
    }
}