import { useEffect } from "react"
import { useState } from "react"
import { useRef } from "react"
import { Outlet, useLocation } from "react-router-dom"
import Footer from "../components/footer/Footer"
import FooterCategorias from "../components/footer/FooterCategorias"
import Navbar from "../components/navbar/Navbar"


const Layout = () => {

    const { pathname } = useLocation()
    //TODO: regresar footer
    const [navbarHeight, setNavbarHeight] = useState(0)

    return (
        <div>
            <Navbar
                setNavbarHeight={setNavbarHeight}
            />
            <div style={{ marginTop: navbarHeight }} >
                <Outlet />
            </div>
            { pathname === '/categorias' ? 
            (
                <FooterCategorias />
            ) : (
                <Footer />
            )
            } 
        </div>
    )
}

export default Layout