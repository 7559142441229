import React, { useState, useRef, useEffect } from "react";
import Card from "react-credit-cards";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../utils/payment";

import { useNavigate } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import "react-credit-cards/es/styles-compiled.css";
import { activateMembership, activatePlan } from "../redux/userDucks";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from "../components/checkoutForm/CheckoutForm";


const Payment = () => {

  const { individual, duo, familiar, membership, userName, userLastName, mail } = useSelector(state => state.userInfo)
  console.log({ individual, duo, familiar })

  // stripe
  const stripePromise = loadStripe('pk_test_51NRMjEFLbR6yfUrb8WdiZ6fOP5vPFhqcbH4vBXD1GqBb1IK3acO0xfXp1jugyYsKvOddE2WdMgY7gKrwt35VFYb700zoX79N1Q')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if(membership){
      navigate('/inicio')
    }
  }, [membership, navigate])

  useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
}, [])

  const [state, setState] = useState({
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    focused: "",
    formData: null,
    loading: false,
  });

  // buttons
  const [selectedPlan, setSelectedPlan] = useState('')
  const [selectedPlanBefore, setSelectedPlanBefore] = useState(false)

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan)
  }
  const handleExpiryKeyDown = (e) => {
    const inputValue = e.target.value;
    const inputLength = inputValue.length;
  
    if (inputLength === 2 && e.key !== 'Backspace') {
      e.target.value = inputValue + '/';
    }
  };

  // console.log(selectedPlan)

  useEffect(() => {
    // console.log({ individual, duo, familiar })
    const checkPlan = () => {
      if(individual === 'pending'){
        setSelectedPlan('individual')
        setSelectedPlanBefore(true)
      } else if (duo === 'pending'){
        setSelectedPlan('duo')
        setSelectedPlanBefore(true)
      } else if (familiar === 'pending'){
        setSelectedPlan('familiar')
        setSelectedPlanBefore(true)
      } else {
        setSelectedPlan('')
        setSelectedPlanBefore(false)
      }
    }

    checkPlan()

  }, [individual, duo, familiar])


 

  return (
    <div key="Payment" >
      <div className="title-payment">
        { selectedPlanBefore ? (
          <h1>Continua con tu <span>plan</span> para continuar</h1>
        ) : (
          <h1>Bienvenido/a <span>{userName}</span><br></br> Por favor selecciona un <span>plan</span> para continuar</h1>
        ) }
      </div>
      <div className={`payment__plans`}>
        <button 
          className={`payment__button ${selectedPlan === 'individual' && 'selected'}`}
          onClick={() => handlePlanSelect('individual')}
        >
          Individual
        </button>
        <button 
          className={`payment__button ${selectedPlan === 'duo' && 'selected'}`}
          onClick={() => handlePlanSelect('duo')}
        >
          Duo
        </button>
        <button 
          className={`payment__button ${selectedPlan === 'familiar' && 'selected'}`} 
          onClick={() => handlePlanSelect('familiar')}
        >
          Familiar
        </button>
      </div>
      <div className="payment__prices" >
          { selectedPlan === 'individual' && <h2>El precio de este plan es de <span>$8</span> por mes</h2>}
          { selectedPlan === 'duo' && <h2>El precio de este plan es de <span>$10</span> por mes</h2>}
          { selectedPlan === 'familiar' && <h2>El precio de este plan es de <span>$20</span> por mes</h2>}
      </div>
      <Elements stripe={ stripePromise  }>
          <CheckoutForm  mail={mail} />
      </Elements>
    </div>
    );
    };
                
                export default Payment;
                
                
                  