import { Container, Wrapper } from "./StyledBannerCourse"

// images


const BannerCourse = ({ category, course }) => {

    return (
        <Container>
            <Wrapper>
                <div className="course">
                    <h1 className="course__category">{category}</h1>
                    <p className="course__title" >{course?.title}</p>
                    <p className="course__author" >{course?.authorName}</p>
                    <p className="course__info" >{course?.authorDescription}</p>
                </div>
            </Wrapper>
        </Container>
    )
}

export default BannerCourse
