import styled from 'styled-components'

export const Container = styled.div`
    background-color: #0B2442;
    padding-bottom: 5rem;
`

export const Section = styled.section`
    margin: ${props => props.center ? '0rem 0rem' : '0rem 0rem 0rem 0rem'};


    .react-tabs__tab-panel--selected{
        padding:3rem !important;
    }

    .video{
        width:100%!important;
        height:60vh!important;
    }

    .iframe-video{
        width:100% !important;
        height:100% !important;
    }

    .chapters:hover{
        cursor:pointer;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(243, 243, 243, 0.7));

    }

    .title{
        background-color: #5588FE;
        height: min-content;
    }

   
    min-height: 70vh;
    span{
        color: #ffff;
    }
    h2 {
        display: flex;
        font-size: 25px;
        color: white;
        margin: 2rem 0;
        align-items: center;
        gap: 1rem;

        img {
            width: 10px;
            margin-top: 5px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .buttons {
        display: flex;
        gap: 1rem;
    }

    .box {
        margin-right: 15rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &__comment {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            gap: 1rem;

            label {
                color: white;
                font-size: 20px;
            }

            textarea {
                height: 200px;
                border-radius: 10px;
                outline: none;
                padding: 1rem;
            }
        }

        &__rate {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            /* align-items: center;
            justify-content: space-between; */
            justify-content: space-between;
            &--title {
                color: white;
                font-size: 20px;
                margin: 0;
                text-align: left;
            }
        }

        &__icons {
            display: flex;
            justify-content: space-evenly;
            gap: 2.5rem;
            img {
                width: 50px;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        button {
            margin: 0 auto;
        }
    }

    .sidebar{
        background-color: #0b2740;
    }

    .learning {
        max-width: 1200px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        &__box {
            width: 50%;
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            img {
                width: 50px;
            }

            p {
                color: white;
                font-weight: 200;
                font-size: 16px;
            }
        }
    }    
`

export const Button = styled.button`
    padding: .8rem 2rem;
    background-color: ${props => props.primary ? 'transparent' : '#339DFF'};
    color: ${props => props.primary ? 'white' : 'white'};
    border: 1px solid #399DFF;
    font-size: 16px;
    font-weight: bold;
    transition: all .3s ease;
    &:hover {
        cursor: pointer;
    }
`

export const ErrorContainer = styled.div`
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .error-msg {
        font-size: 40px;
        color: white;
        font-weight: bold;
    }
`