import axios from '../api/axios'

import { testimonialUrl } from '../api/url'

const initialState = {
    testimonials: [],
    testimonial: {},
    loading: false,
    error: null,
    successMessage: null
}


const START_FETCHING = 'START_FETCHING'
const FETCHING_SUCCESS = 'FETCHING_SUCCESS'
const FETCHING_FAIL = 'FETCHING_FAIL'


export default function reducer(state = initialState, action = {}){
    switch(action.type) {

        case START_FETCHING: 
            return {
                ...state,
                loading: true
            }

        case FETCHING_SUCCESS:
            return {
                ...state,
                testimonials: action.payload,
                loading: false,
                error: null,
                successMessage: 'Datos cargados correctamente'
            }

        case FETCHING_FAIL:
            return {
                ...state,
                testimonials: [],
                testimonial: {},
                loading: false,
                error: action.payload,
                successMessage: ''

            }

        default:
            return state
    }
}


export const getAllTestimonials = () => {
    return async dispatch => {
        dispatch(startFetching())
        try {
            const { data } = await axios.get(testimonialUrl.getAllTestimonials)                
            dispatch(fetchDataSuccess(data.data))
        } catch (error) {
            dispatch({
                type: FETCHING_FAIL,
                payload: error.response.data.message
            })
        }
    }
}

const fetchDataSuccess = (data) => {
    return {
        type: FETCHING_SUCCESS,
        payload: data
    }
}

const startFetching = () => ({
    type: START_FETCHING
})