import styled from 'styled-components'

export const Container = styled.div`
    background-color: #0B2442;
    padding-bottom: 5rem;
`

export const Section = styled.div`
    margin: ${props => props.center ? '5rem 15rem' : '5rem auto 5rem 15rem'};
    h2 {
        display: flex;
        font-size: 25px;
        color: white;
        margin: 2rem 0;
        gap: 1rem;
    }
`
export const Top = styled.div`
    padding: 5rem 15rem;
    display: flex;
    gap: 2.5rem;
`

export const Left = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    .instructor {
        h2 {
            color: #5786FF;
            font-weight: 400;
            margin: 0;
            font-size: 30px;
        }

        &__name {
            color: white;
            font-size: 40px;
            font-weight: bold;
            margin: 0
        }

        &__category {
            color: #5786FF;
            font-size: 20px;
        }
    }

    .about {
        &__title {
            color: #5786FF;
            font-size: 25px;
        }

        &__description {
            color: white;
            font-weight: 300;
            line-height: 1.6;
            font-size: 18px;
            text-align: justify;
        }
    }
`
export const Right = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .image-website {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;

        img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
        }

        button {
            background-color: transparent;
            border: none;
            color: #339DDF;
            border: 1px solid #339DDF;
            padding: .5rem 1.5rem;
            border-radius: 5px;
            font-size: 18px;
            font-weight: bold;
            transition: all .3s ease;

            &:hover {
                cursor: pointer;
                background-color: #339DDF;
                color: white;
            }
        }
    }
`