import styled from 'styled-components' 

export const Item = styled.div`
    /* width: ${props => props.circle ? '170px' : '100%'}; */
    width: ${props => props.circle ? '150px' : '100%'};
    height: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    /* overflow: hidden; */
    position: relative;

    &:hover {
        cursor: ${props => !props.comingSoon && 'pointer'};
        pointer-events: ${props => props.comingSoon && 'none'};
    }

    .over {
        position: absolute;
        bottom: 5px;
        text-align: center;
        font-weight: 500;
    }

    .normal {
        font-size: 18px;
        font-weight: 700; 
    }

    .image-container{
        width: 100%;
        /* height: 100%; */
        /* height: ${props => props.circle ? '100%' : '165px'}; */
        height: ${props => props.circle ? '100%' : '100%'};
        border-radius: ${props => props.circle ? '0px' : '20px'};
        overflow: hidden;
        position: relative;
        
        @media (min-width: 600px){
            height: ${props => props.circle ? '100%' : '100%'};
        }
        
        @media (min-width: 992px){
            height: ${props => props.circle ? '100%' : '100%'};
        }

        @media (min-width: 1500px){
            height: ${props => props.circle ? '100%' : '165px'};
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: ${props => !props.circle && 'rgba(0, 0, 0, 0.30)'};
            /* opacity: 0.1; */
            /* z-index: 1; */
        }

        .image {
            height: auto;
            /* height: 190px;
            width: 190px;
            border-radius: 50%;
            object-fit: cover; */
            object-fit: cover;
        }

        .percentage {
            width: 100%;
            height: 8px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.25);

            .bar {
                width: ${props => props.percentage && `${props.percentage}%`};
                background-color: #339DFF;
                height: 100%;
            }
        }
    }


    p {
        font-size: 15px;
        color: white;
        font-weight: 300;
    }

    .play-icon {
        position: absolute;
        width: 20px;
        left: 15px;
        bottom: ${props => props.title ? '65px' : '15px'};
        border-radius: 0;
        transition: all .3s ease;
    }

    &:hover .play-icon {
        transform: scale(1.2);
    }
`