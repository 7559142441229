import AppRouter from "./router/AppRouter";
import 'semantic-ui-css/semantic.min.css';
import { SkeletonTheme } from 'react-loading-skeleton'

function App() {
  
  return (
      <SkeletonTheme
        baseColor="#202020"
        highlightColor="#525252"
      >
        <AppRouter />
      </SkeletonTheme>
  );
}

export default App;
