import { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

//images
import AhorroImage from '../../assets/private-home/iconos/03-ahorro.svg'
import PresupuestoImage from '../../assets/private-home/iconos/04-presupuesto.svg'
import IngresosImage from '../../assets/private-home/iconos/05-ingresos-egresos.svg'
import InversionesImage from '../../assets/private-home/iconos/06-inversiones.svg'
import DeudasImage from '../../assets/private-home/iconos/07-deudas.svg'
import { CategoryCard, Wrapper } from './StyledCategoriesItem'

const CategoriesItems = () => {
    const [categories, setCategories] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        setCategories([
            {
                id: 1,
                title: 'Ahorro',
                img: AhorroImage
            },
            {
                id: 2,
                title: 'Presupuestos',
                img: PresupuestoImage
            },
            {
                id: 3,
                title: 'Ingresos y egresos',
                img: IngresosImage
            },
            {
                id: 4,
                title: 'Inversiones',
                img: InversionesImage
            },
            {
                id: 5,
                title: 'Deudas',
                img: DeudasImage
            },
        ])
    }, [])

    const handleNavigate = item => {
        if(item.title === 'Ahorro'){
            navigate('/inicio/categorias/ahorro')
            return
        }
        if(item.title === 'Presupuestos'){
            navigate('/inicio/categorias/presupuesto personal')
            return
        }
        if(item.title === 'Ingresos y egresos'){
            navigate('/inicio/categorias/ingresos')
            return
        }
        if(item.title === 'Inversiones'){
            navigate('/inicio/categorias/inversiones')
            return
        }
        if(item.title === 'Deudas'){
            navigate('/inicio/categorias/deudas')
            return
        }
    }

    return (
        <Wrapper>
            { categories.map(category => (
                <CategoryCard key={category.id} onClick={() => handleNavigate(category)} >
                    <img src={category.img} alt={`Imagen ${category.title}`} />
                    <p>{category.title}</p>
                </CategoryCard>
            )) }
        </Wrapper>
    )
}

export default CategoriesItems