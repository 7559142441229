import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* gap: 1rem; */
    gap: 1rem;
`

export const CategoryCard = styled.div`
    background-color: white;
    padding: 1rem 1.5rem;
    display: flex;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    transition: all .3s ease;
    width: 200px;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    img {
        width: 50px;
    }

    p {
        font-size: 18px;
    }
`