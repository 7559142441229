import "./Plans.css";
import CheckImg from "../../assets/home/iconos/09-check.svg";
import AppleSVG from "../../assets/home/iconos/010-apple.svg";
import AndroidSVG from "../../assets/home/iconos/011-android.svg";

import CustomButton from "../CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { activeDuo, activeFamiliar, activeIndividual } from "../../redux/userDucks";

const Plans = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleIndividual = () => {
    dispatch(activeIndividual());
    navigate("/register");
  };

  const handleDuo = () => {
    dispatch(activeDuo());
    navigate("/register");
  };

  const handleFamiliar = () => {
    dispatch(activeFamiliar());
    navigate("/register");
  };

  return (
    <div className="plans container">
      <h2 className="plans__title">¡Puedes ser miembro hoy!</h2>
      <p className="plans__description">
        Obtén acceso exclusivo e ilimitado a más de 500 lecciones para alcanzar <br></br>
        <span>tu libertad financiera.</span> Aprende cuando y donde quieras.
      </p>
      <div className="plans__container">
        <table className="plans-table">
          <thead>
            <tr>
              <th></th>
              <th>
                Plan <br />
                <span>Individual</span>
              </th>
              <th>
                Plan <br />
                <span>Duo</span>
              </th>
              <th>
                Plan <br />
                <span>Familiar</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span>Precio</span> (precio mensual) facturado anualmente
              </td>
              <td>$8</td>
              <td>$10</td>
              <td>$20</td>
            </tr>
            <tr>
              <td>
                <span>Dispositivos que se pueden ver al mismo tiempo</span>
              </td>
              <td>1</td>
              <td>2</td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <span>Descargar contenido</span>
              </td>
              <td>No</td>
              <td>Si</td>
              <td>Si</td>
            </tr>
            <tr>
              <td>
                <span>Acceso a +500 cursos de las 5 categorías</span>
              </td>
              <td>
                <img src={CheckImg} alt="check" />
              </td>
              <td>
                <img src={CheckImg} alt="check" />
              </td>
              <td>
                <img src={CheckImg} alt="check" />
              </td>
            </tr>
            <tr>
              <td>
                <span>Mirar en computadora, TV, celular o tablet</span>
              </td>
              <td>
                <img src={CheckImg} alt="check" />
              </td>
              <td>
                <img src={CheckImg} alt="check" />
              </td>
              <td>
                <img src={CheckImg} alt="check" />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td>
                <CustomButton onClickFunction={handleIndividual}>Unirme</CustomButton>
              </td>
              <td>
                <CustomButton primary onClickFunction={handleDuo}>
                  Unirme
                </CustomButton>
              </td>
              <td>
                <CustomButton onClickFunction={handleFamiliar}>Unirme</CustomButton>
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="plans-mobile">
          <div className="item">
            <p className="title">Individual</p>
            <p className="precio">
              Precio: <span>$8</span>
            </p>
            <span></span>
            <button onClick={handleIndividual}>Unirme</button>
          </div>
          <div className="item active">
            <p className="title">Duo</p>
            <p className="precio">
              Precio: <span>$10</span>
            </p>
            <span></span>
            <button className="active" onClick={handleDuo}>
              Unirme
            </button>
          </div>
          <div className="item">
            <p className="title">Familiar</p>
            <p className="precio">
              Precio: <span>$20</span>
            </p>
            <span></span>
            <button onClick={handleFamiliar}>Unirme</button>
          </div>
        </div>
        <div className="plans-apps">
          <h3 className="plans-apps__title" style={{ margin: 0 }}>
            También disponible en:
          </h3>
          <div className="plans-apps__ios">
            <img src={AppleSVG} alt="Icono ios" />
          </div>
          <div className="plans-apps__android">
            <img src={AndroidSVG} alt="Icono android" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
