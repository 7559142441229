import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './Home.css'
import 'swiper/css'
import ImagenSiete from '../../assets/home/iconos/01-7dias.svg'
import Isotipo from '../../assets/home/iconos/02-isotipo.png'

import Instructores from '../../components/instructores/Instructores'
import Cards from '../../components/cards/Cards'
import FormHome from '../../components/form-home/FormHome'
import CustomButton from '../../components/CustomButton'
import Testimonial from '../../components/testimonial/Testimonial'
import Plans from '../../components/Plans/Plans'
import { cleanSevenDayTrial, cleanDualMembership, cleanMemberships } from '../../redux/userDucks'
import Spinner from '../../components/spinner/Spinner'
import { Link } from 'react-router-dom'
import axios from '../../api/axios'
import { cleanPublicCategoryPage } from '../../redux/educationDucks'
import YouTube from 'react-youtube';

import styled from 'styled-components';

const Button = styled.button`
        background-color: white;
        border: none;
        color: black;
        padding: .5rem 3rem;
        font-size: 1.3rem;
        -webkit-box-shadow: 0px 3px 17px -5px rgba(0,0,0,0.41);
        -moz-box-shadow: 0px 3px 17px -5px rgba(0,0,0,0.41);
        box-shadow: 0px 3px 17px -5px rgba(0,0,0,0.41);
        font-weight: 600;
        cursor: pointer;
        width: 100%;
        height: auto;
        border-radius: 20px;
        @media (min-width: 768px) {
            width: 75%;
            border-radius: 0;
        }
`

const Home = () => {

    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.userInfo)
    const [lastCourse, setLastCourse] = useState({})
    const [loading2, setLoading2] = useState(false)

    const nuevoRef = useRef(null)
    const planesRef = useRef(null)

    console.log('aquí2')

    useEffect(() => {
        dispatch(cleanSevenDayTrial())
        dispatch(cleanDualMembership())
    }, [dispatch])

    useEffect(() => {
        //const baseURL = 'http://localhost:3000/api/'
        const getLastCourse = async () => {
            setLoading2(true)
            //const url = `${baseURL}video/last-course`
            const { data } = await axios.get(`/video/last-course`)
            setLastCourse(data.data)
            console.log(data.data)
            setLoading2(false)
        }
        getLastCourse()
    }, [])

    useEffect(() => {
        dispatch(cleanMemberships())
    }, [dispatch])

    // clean category page
    useEffect(() => {
        dispatch(cleanPublicCategoryPage())
    }, [dispatch])

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === "#nuevo" && nuevoRef.current) {
          nuevoRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        //   const extraOffset = 70;
          window.scrollBy(0);
        } else if (hash === "#planes" && planesRef.current) {
          planesRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        //   const extraOffset = 70;
          window.scrollBy(0);
        }
      }, []);

    return (
        <>
            <section>
                <div className="banner-container" >
                    <div className="form-text-container">
                        <div className='container flex'>
                            <div className='text-container'>
                                <img src={ImagenSiete} alt="7 dias" />
                                <div className='text'>
                                    <p>días de prueba gratis</p>
                                    <p>¡Oferta especial de lanzamiento, no esperes más!</p>
                                </div>
                            </div>
                            <div className='form-container'>
                                <FormHome type={'trial'} buttonValue="¡Únete ahora!" />
                                { loading && <Spinner /> }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <main className='main-container container'>
                <div className='logo-container'>
                    <img src={Isotipo} alt="Imagen logo" />
                </div>
                <h2>¿Qué es LivFree?</h2>
                <p>Plataforma de cursos online dirigido por expertos profesionales que te ayudarán<br />
                    a mejorar tus conocimientos y habilidades para alcanzar <span>tu libertad financiera.</span>
                </p>
                <Cards />
            </main>
            <section style={{backgroundColor: '#f1f1f1'}} >
                <Instructores />
            </section>
            <section className='video-section' name="nuevo" ref={nuevoRef} >
                <div className='container'>
                    <h2>Nuevas clases todos los meses</h2>
                    { loading2 ? 
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner /> 
                        </div>
                        : 
                        <div className='video-section__container'>
                            <div className='video-section__portada'>
                                <img src={lastCourse?.img} alt="Portada video" className='portada-video' />
                                {/* <YouTube videoId="https://youtu.be/M5x6OfCojlI" className='portada-video'  /> */}
                            </div>
                            <div className='video-section__item'>
                                <h3 className='video-section__title'>{lastCourse?.category?.toUpperCase()}</h3>
                                <p className='video-section__description'>{lastCourse?.title}</p>
                                <p className='video-section__name'>{lastCourse?.author}</p>
                                <Link to="/categorias">
                                    <CustomButton width='100%' fontSize='1.2rem' invisible={true}>
                                        Explora más
                                    </CustomButton>
                                </Link>
                                <Link to="/categorias">
                                    <Button>Explora más</Button>
                                </Link>
                            </div>
                        </div>
                    }
                </div>
            </section>
            <section className="container" id="resultados">
                <Testimonial />
            </section>
            <section style={{ backgroundColor: '#f1f1f1' }} name="planes" ref={planesRef} >
                <Plans />
            </section>
        </>
    )
}

export default Home