import { Navigate } from "react-router-dom"
import { useSelector } from 'react-redux'

const ProtectedRoute = ({children, currentUser, membership}) => {

    const { verified } = useSelector(state => state.userInfo)

    if(!currentUser){
        return <Navigate to="/login" />
    }
    if(currentUser && !membership){
        return <Navigate to="/payment" />
    }
    if(currentUser && membership && verified){
        return children
    }

}

export default ProtectedRoute