import { Link } from 'react-router-dom'
import './Card.css'

const Card = ({ img, info }) => {

    return (
        <Link to={`/categorias?cat=${info.toLowerCase()}`}>
            <div className='card-container'>
                <div className='card'>
                    <img src={img} alt={info} />
                </div>
                <p>{info}</p>
            </div>
        </Link>
    )
}

export default Card
