import { useEffect, useState } from 'react'
import PrivateCategorySlider from "../../../components/private-category-slider/PrivatedCategorySlider"
import PrivateSwiper from "../../../components/private-swiper/PrivateSwiper"
import { Container, Section } from "./StyledPrivatedCategories"
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataByPrivateCategory } from '../../../redux/educationDucks'

import { useParams } from 'react-router-dom'

// images
import Image1 from '../../../assets/categorias/imagenes/01-Capitulos-Ahorro.jpg'
import Image2 from '../../../assets/categorias/imagenes/02-Ahorro.jpg'
import Expert1 from '../../../assets/private-home/imagenes/04-Expert-1.png'
import Expert2 from '../../../assets/private-home/imagenes/04-Expert-2.png'
import Expert3 from '../../../assets/private-home/imagenes/04-Expert-3.png'
import Expert4 from '../../../assets/private-home/imagenes/04-Expert-4.png'
import Expert5 from '../../../assets/private-home/imagenes/04-Expert-5.png'
import Expert6 from '../../../assets/private-home/imagenes/04-Expert-6.png'
import ComingSoonImage from '../../../assets/categorias/imagenes/03-Proximamente.jpg'

const PrivatedCategories = () => {

    const [videosPopularCourse, setVideosPopularCourse] = useState([])
    const [temporaryAuthors, setTemporaryAuthors] = useState([])
    const [tendingLocal, setTendingLocal] = useState([])

    const { topCourse, authorsByCategory, comingSoonByCategory, tendingByCategory, loading } = useSelector(state => state.courses)
    const {category} = useParams()

    const dispatch = useDispatch()
    
    useEffect(() => {
        if(category){
            const getCourses = () => {
                switch(category) {
                    case 'ahorro':
                        dispatch(fetchDataByPrivateCategory(1))
                        break;
                    case 'presupuesto personal':
                        dispatch(fetchDataByPrivateCategory(2))
                        break;
                    case 'ingresos':
                        dispatch(fetchDataByPrivateCategory(3))
                        break;
                    case 'inversiones':
                        dispatch(fetchDataByPrivateCategory(4))
                        break;
                    case 'deudas':
                        dispatch(fetchDataByPrivateCategory(5))
                        break;
                    case 'libertad financiera':
                        dispatch(fetchDataByPrivateCategory(6))
                        break;
                    default:
                        console.log('testing...')
                }        
            }
            getCourses()
        }
    }, [category, dispatch])

    useEffect(() => {
        if(!loading && topCourse){
            const newItems = topCourse.videoContents?.map(item => {
                return {
                    id: topCourse.id,
                    thumbnail: item.thumbnail,
                    title: item.title,
                    url: item.url,
                    viewCount: item.viewCount,
                    category: category
                }
            })
            setVideosPopularCourse(newItems)
        }
    }, [loading, topCourse, category])

    useEffect(() => {
        if(!loading && authorsByCategory){
            const newAuthors = authorsByCategory.map(author => {
                return {
                    ...author,
                    cover_image: author.photo,
                    title: `${author.name} ${author.lastname}`
                }
            })
            setTemporaryAuthors(newAuthors)
        }
    }, [loading, authorsByCategory])

    useEffect(() => {
        if(!loading && tendingByCategory){
            const newItems = tendingByCategory?.map(item => {
                return {
                    ...item,
                    category: category
                }
            })
            setTendingLocal(newItems)
        }
    },[loading,tendingByCategory, category])

    const capitulesItems1 = [
        {
            id: 1,
            title: 'Planea tu futuro financiero - Cap.1',
            img: Image1
        },
        {
            id: 2,
            title: 'Planea tu futuro financiero - Cap.2',
            img: Image1
        },
        {
            id: 3,
            title: 'Planea tu futuro financiero - Cap.3',
            img: Image1
        },
        {
            id: 4,
            title: 'Planea tu futuro financiero - Cap.4',
            img: Image1
        },

        
    ]

    const capitulesItems2 = [
        {
            id: 5,
            img: Image2
        },
        {
            id: 6,
            img: Image2
        },
        {
            id: 7,
            img: Image2
        },
        {
            id: 8,
            img: Image2
        },
        {
            id: 9,
            img: Image2
        },
        {
            id: 10,
            img: Image2
        },
        {
            id: 11,
            img: Image2
        },
    ]

    const expertItems = [
        {
            id: 12,
            img: Expert1
        },
        {
            id: 13,
            img: Expert2
        },
        {
            id: 14,
            img: Expert3
        },
        {
            id: 15,
            img: Expert4
        },
        {
            id: 16,
            img: Expert5
        },
        {
            id: 17,
            img: Expert6
        },
    ]

    const comingSoonItems = [
        {
            id: 18,
            img: ComingSoonImage
        },
        {
            id: 19,
            img: ComingSoonImage
        },
        {
            id: 20,
            img: ComingSoonImage
        },
    ]

    const tendingItems = [
        {
            id: 21,
            img: Image2
        },
        {
            id: 22,
            img: Image2
        },
        {
            id: 23,
            img: Image2
        },
        {
            id: 24,
            img: Image2
        },
        {
            id: 25,
            img: Image2
        },
        {
            id: 26,
            img: Image2
        },
        {
            id: 27,
            img: Image2
        },
    ]

    return (
        <Container>
            <PrivateCategorySlider privated />
            <Section>
            { !loading && Object.keys(topCourse).length > 0 ? <h2>{topCourse.title}</h2> : <h2>Cargando...</h2>}
                <PrivateSwiper 
                    data={videosPopularCourse}
                    over={true}
                />
            </Section>
            {/* <Section>
                <h2>Ahorro a corto plazo</h2>
                <PrivateSwiper 
                    data={capitulesItems2}
                />
            </Section> */}
            <Section>
                <h2>Explora a los expertos del { category }</h2>
                <PrivateSwiper 
                    data={temporaryAuthors}
                    circle
                />
            </Section>
            <Section>
                <h2>Próximamente</h2>
                <PrivateSwiper 
                    data={comingSoonByCategory}
                    comingSoon={true}
                />
            </Section>
            <Section>
                <h2>En tendencia</h2>
                <PrivateSwiper 
                    data={tendingLocal}
                />
            </Section>
        </Container>
    )
}

export default PrivatedCategories