import styled from "styled-components"
import FormHome from "../form-home/FormHome"

//images
import Logo from '../../assets/home/iconos/020-Logo-futter.svg'
import FacebookIcon from '../../assets/home/iconos/015-FB.svg'
import InstagramIcon from '../../assets/home/iconos/016-IG.svg'
import TikTokIcon from '../../assets/home/iconos/017-TikTok.svg'
import LinkedinIcon from '../../assets/home/iconos/018-Linkedin.svg'
import YoutubeIcon from '../../assets/home/iconos/019-Youtube.svg'

const Container = styled.div`
    background-color: #0B2442;
    padding: 5rem 0;

`

const Wrapper = styled.div`
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;

    h2 {
        color: #5786FF; 
        text-align: center;
        font-size: 30px;
        margin: 0;
    }

    .subscribe__text {
        font-size: 15px;
        color: white;
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
`

const SubscribeWrapper = styled.div`
    background-color: white;
    border-radius: 20px;
    padding: 1rem 3rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;

    .offer {
        margin: 0;
        color: #5786ff;
        font-size: 40px;
        font-weight: bold;
    }

    .membership {
        display: flex;
        flex-direction: column;
        /* gap: 1rem; */

        .membership__text1, .membership__text2 {
            line-height: 1.2;
            margin: 0;
        } 

        .membership__text1 {
            font-weight: 600;
        }

        .membership__text2 {
            font-weight: 300;
        }
    }
`

const LogoIconsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    img {
        width: 250px;
    }

    .links {
        display: flex;
        align-items: center;
        gap: 1rem;

        a {
            color: white;
            font-size: 12px;
            font-weight: 200;
        }

        @media(min-width: 768px){
            gap: 5rem;
        }
    }

    .icons {
        display: flex;
        justify-content: center;
        gap: 2rem;

        img {
            width: 25px;
            height: 20px;
            object-fit: contain;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .copyright {
        font-size: 12px;
        font-weight: 200;
        color: white;
    }
`

const FooterCategorias = () => {
    return (
        <Container>
            <Wrapper>
                <h2>¿Quieres descubrir más?</h2>
                <p className="subscribe__text">Suscríbete hoy y obtén acceso exclusivo e ilimitado a más de 500 <br />lecciones para alcanzar tu libertad financiera</p>
                <SubscribeWrapper>
                    <p className="offer">2X1</p>
                    <div className="membership">
                        <p className="membership__text1">2 membresías al precio de 1</p>
                        <p className="membership__text2">¡Oferta especial de lanzamiento, no esperes más!</p>
                    </div>
                </SubscribeWrapper>
                <FormHome />
                <LogoIconsWrapper>
                    <img src={Logo} alt={'logo'}/>
                    <nav className="links">
                        <a href="/#">Privacidad</a>
                        <a href="/#">Términos</a>
                        <a href="/#">Ayuda</a>
                        <a href="/#">Dispositivos</a>
                    </nav>
                    <div className="icons">
                        <img src={FacebookIcon} alt="icono facebook" />
                        <img src={InstagramIcon} alt="icono instagram" />
                        <img src={TikTokIcon} alt="icono tiktok" />
                        <img src={LinkedinIcon} alt="icono linkedin" />
                        <img src={YoutubeIcon} alt="icono youtube" />
                    </div>
                    <p className="copyright">2023 LivFree. Todos los derechos reservados.</p>
                </LogoIconsWrapper>
            </Wrapper>
        </Container>
    )
}

export default FooterCategorias