


import {  useRef, useCallback } from 'react'
import React, { useEffect, useState } from 'react';

import { Button, Container, ErrorContainer, Section } from "./StyledPrivateChapter"

import ReactPlayer from "react-player";

import { FaClock } from 'react-icons/fa';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

//data
import {  getvideoByUserandId, getVideosByCourseId , markVideoAsWatched, updateProgress} from '../../../redux/educationDucks'
import PrivateSwiper from "../../../components/private-swiper/PrivateSwiper"



const PrivateChapter = () =>{

    const { userId } = useSelector((state) => state.userInfo);



    const { videoId: videoId, course: courseId } = useParams();

    




    const videoData = useSelector(state => state.courses.video);



    const { recommendedCourses } = useSelector((state) => state.courses);
    const [recommendedCoursesLocal, setRecommendedCoursesLocal] = useState([]);
    const { videos, error, loading, course } = useSelector((state) => state.courses);
    const navigate = useNavigate();
    const dispatch = useDispatch();



    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])





    useEffect(() => {
      // Llama a la acción getvideoByUserandId con el userId y videoId adecuados
      dispatch(getvideoByUserandId(userId, videoId)); // Asegúrate de que tengas un valor válido para videoId
        
    }, [ userId, videoId]); // Asegúrate de que videoId esté definido en tu componente
  

    // Puedes usar useSelector para acceder al video recuperado

    useEffect(() => {
        if (!loading && recommendedCourses) {
          const newItems = recommendedCourses
            .filter(course => course.course != null)
            .map(course => {
              return {
                id: course.course.id,
                title: course.course.title,
                cover_image: course.course.cover_image,
                category: course.category
              }
            });
          setRecommendedCoursesLocal(newItems)
        }
    }, [loading, recommendedCourses]);

    useEffect(() => {
        dispatch(getVideosByCourseId(courseId))
    }, [dispatch, courseId])
  



    const [progress, setProgress] = useState(0);


    console.log("video", videoData)

    const playVideo = useCallback(
        (item) => {
    
          dispatch(markVideoAsWatched(item.id, 0)).then(() => {
            navigate(`/inicio/course/${courseId}/video/${item.id}`);
          })
    
        },
    );
    

    const [playing, setPlaying] = useState(true);
    const [vueltas, setVueltas] = useState(0);
    const playerRef = useRef(null); // Referencia para el ReactPlayer
    useEffect(() => {
        let interval;
        if (playing) {
            interval = setInterval(() => {
                let currentTime = playerRef.current.getCurrentTime();
                console.log(currentTime);
                dispatch(updateProgress(videoId*1, currentTime * 1000));

            }, 60000); // 60000 milisegundos = 60 segundos
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [playing]); 

    // const retrieveVideoMetadata = async (videoId) => {
    //     try {
    //       const response = await drive.files.get({
    //         fileId: videoId,
    //         fields: 'id, title, mimeType, webContentLink', // Adjust fields as needed
    //       });
    //       const videoMetadata = response.data;
      
    //       // Use videoMetadata.webContentLink for ReactPlayer
    //       // ...
    //       return videoMetadata.webContentLink;
    //     } catch (error) {
    //       console.error('Error retrieving video metadata:', error);
    //       // Handle errors appropriately
    //     }
    // };

    const [isReady, setIsReady] = useState(false);


    useEffect(() => {
        if (isReady) {
            // Solo busca al tiempo inicial una vez
            playerRef.current.seekTo(videoData.progress/1000);
            setVueltas(vueltasActual => {
                const nuevasVueltas = videoData.progress/60000;
                
                return nuevasVueltas;
            });
        }
    }, [isReady]);


    return (
        <Section className='row justify-content-center w-100'>
                    
                {videoData  && 
                <>
                <div className='col-md-8 row m-0 p-0'>
                    <div className='col-md-12 p-0 video' >
                        
                        <ReactPlayer
                            ref={playerRef}
                            url={videoData.videoContent.url}
                            playing
                            controls
                            width="100%"
                            height="100%"
                            onPlay={() => setPlaying(true)}
                            onReady={() => setIsReady(true)} 
                        />
                        
                    </div>
                    
                    <div className='title w-100 d-flex justify-content-center'>
                        <h2>{videoData.videoContent.educationItem.title} : {videoData.videoContent.title}</h2>
                    </div>
                    <Tabs className='width:100%'>
                        <TabList>
                            <Tab><span className='text-dark m-4'>DESCRIPCIÓN DEL CURSO</span></Tab>
                            {/* <Tab><span className='text-dark m-4'>PREGUNTAS RESPUESTAS</span></Tab> */}
                            <Tab><span className='text-dark m-4'>QUIÉN IMPARTE EL CURSO</span></Tab>
                            <Tab><span className='text-dark m-4'>CURSOS SIMILARES</span></Tab>
                        </TabList>

                        <TabPanel>
                            <span className='text-dark '>{videoData.videoContent.educationItem.description}</span>
                        </TabPanel>
                        {/* <TabPanel >
                            <span className='text-dark '>hello world Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</span>
                        </TabPanel> */}
                        <TabPanel>
                            <h2 className='text-dark'>{videoData.videoContent.educationItem.author.name} {videoData.videoContent.educationItem.author.lastname}</h2>
                            <span className='text-dark '>
                                {videoData.videoContent.educationItem.author.description}    
                            </span>
                        </TabPanel>
                        <TabPanel >
                            {
                              recommendedCoursesLocal && 
                              <>
                                <h2 className='text-dark '>Recomendados para ti</h2>
                                <PrivateSwiper 
                                    data={recommendedCoursesLocal}
                                    over={true}
                                />
                              </>  
                            }
                            
                        </TabPanel>
                    </Tabs>
                </div>
                <div className='col-md-4 sidebar p-0'>
                    <div className='w-100 d-flex justify-content-center p-2'>
                        <h2>{videoData.videoContent.educationItem.title} - CAPÍTULOS</h2>
                    </div>


                    {videos.map((video, index) => (
                        <div key={index} className='w-100 p-4 row chapters' onClick={() => playVideo(video)}>
                            <div className='col-md-4'>
                            <img src={video.thumbnail} alt={`Thumbnail del capítulo ${index + 1}`} />
                            </div>
                            <div className='col-md-7'>
                            <span>{video.title}</span>
                            <div className='pt-3 pl-3'>
                                <span><FaClock /> {Math.floor(video.duration / 60)} min</span>
                            </div>
                            </div>
                        </div>
                    ))}
                </div>
                </>
                }
              

            

        </Section>
        
    )

}


export default PrivateChapter