import React from 'react'
import { Container, Wrapper } from './StyleDetailsCourse'

const DetailsCourse = ({course}) => {

    console.log({course})

    return (
        <Container>
            <Wrapper>
                <p className='details'>
                {course.description}
                </p>
            </Wrapper>
        </Container>
    )
}

export default DetailsCourse