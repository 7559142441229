export const validateRegisterValues = values => {
    const errors = {}
    if(!values.email){
        errors.email = 'El email es obligatorio'
    } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)){
        errors.email = 'Formato email inválido'
    }

    if(!values.name){
        errors.name = 'El nombre es obligatorio'
    }
    if(!values.lastname){
        errors.lastname = 'El apellido es obligatorio'
    }
    if(!values.password){
        errors.password = 'La contraseña es obligatoria'
    } else if(values.password.length < 5) {
        errors.password = 'La contraseña debe tener al menos 5 caracteres'
    }
    return errors
}