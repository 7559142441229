import { useEffect, useState } from "react"
import CategoriesItems from "../../../components/categories_items/CategoriesItems"
import PrivateCategorySlider from "../../../components/private-category-slider/PrivatedCategorySlider"
import { Container, Section } from "./StyledPrivateHome"

import { useSelector, useDispatch } from 'react-redux'

//images
import ArrowImage from '../../../assets/private-home/iconos/08-flecha-azul.svg'

//components
import PrivateSwiper from "../../../components/private-swiper/PrivateSwiper"
import { fetchDataPrivateHome, getKeepWatching } from "../../../redux/educationDucks"

const PrivatedHome = () => {

    const [recommendedCoursesLocal, setRecommendedCoursesLocal] = useState([])
    const [temporaryAuthors, setTemporaryAuthors] = useState([])
    const [temporaryTending, setTemporaryTending] = useState([])
    const [watchingAgain, setWatchingAgain] = useState([])
    const [myListLocal, setMyListLocal] = useState([])
    const [keepWatching, setKeepWatching] = useState([])

    const { userId } = useSelector(state => state.userInfo)
    const { recommendedCourses, myList, myFavoriteExperts, comingSoonCourses, tendingCourses, watchingAgainCourses, loading } = useSelector(state => state.courses)
    const [activeFirstVideo, setActiveFirstVideo] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if(userId) {
            dispatch(fetchDataPrivateHome(userId))
        }
    }, [dispatch, userId])


    useEffect(() => {
        if (userId) {
            const fetchData = async () => {
                try {
                    // Asegúrate de que `getKeepWatching` devuelve una promesa
                    // que se resuelve con los datos deseados.
                    let data = await dispatch(getKeepWatching(userId));
                    // Suponiendo que `getKeepWatching` es una acción de Redux Thunk,
                    // `data` debería contener el resultado de la promesa.
                    
                    const newArray = data.videoProgress.map(item => item.videoContent);

                    setKeepWatching(newArray);
                    console.log("keep",newArray);
                } catch (error) {
                    // Maneja cualquier error que pueda ocurrir
                    console.error("Error fetching data:", error);
                }
            };
    
            fetchData();
        }
    }, [dispatch, userId]);

    // useEffect(() => {
    //     if(!loading && recommendedCourses) {
    //         const newItems = recommendedCourses.map(course => {
    //             return {
    //                 id: course.course?.id,
    //                 title: course.course?.title,
    //                 cover_image: course.course?.cover_image,
    //                 category: course.category
    //             }
    //         })
    //         setRecommendedCoursesLocal(newItems)
    //     }
    // }, [loading, recommendedCourses])

    useEffect(() => {
        if (!loading && recommendedCourses) {
          const newItems = recommendedCourses
            .filter(course => course.course != null)
            .map(course => {
              return {
                id: course.course.id,
                title: course.course.title,
                cover_image: course.course.cover_image,
                category: course.category
              }
            });
          setRecommendedCoursesLocal(newItems)
        }
      }, [loading, recommendedCourses]);

    useEffect(() => {
        if(!loading && myFavoriteExperts) {
            const newItems = myFavoriteExperts.map(author => {
                return {
                    ...author,
                    cover_image: author.photo,
                    title: `${author.name} ${author.lastname}`
                }
            })
            setTemporaryAuthors(newItems)
        }
    }, [loading, myFavoriteExperts])

    // useEffect(() => {
    //     if(!loading && tendingCourses) {
    //         const newItems = tendingCourses.map(course => {
    //             return {
    //                 id: course.course?.id,
    //                 title: course.course?.title,
    //                 cover_image: course.course?.cover_image,
    //                 category: course.category
    //             }
    //         })
    //         setTemporaryTending(newItems)
    //     }
    // }, [loading, tendingCourses])

    useEffect(() => {
        if (!loading && tendingCourses) {
          const newItems = tendingCourses
            .filter(course => course.course != null)
            .map(course => {
              return {
                id: course.course.id,
                title: course.course.title,
                cover_image: course.course.cover_image,
                category: course.category
              }
            });
          setTemporaryTending(newItems)
        }
      }, [loading, tendingCourses]);

    useEffect(() => {
        if(!loading && watchingAgainCourses){
            const newItems = watchingAgainCourses.map(course => {
                return {
                    id: course.courseId,
                    title: `${course.courseTitle} ${course.videoTitle}`,
                    cover_image: course.videoThumbnail,
                    category: course.category
                }
            })
            setWatchingAgain(newItems)
        }
    }, [loading, watchingAgainCourses])

    useEffect(() => {
        if(!loading && myList){
            const newItems = myList.reduce((acc, el) => {
                const existsObject = acc.find(obj => obj.id === el.id)
                if(!existsObject){
                    acc.push(el)
                }       
                return acc;
            }, [])
            setMyListLocal(newItems)
        }
    }, [loading, myList])

    

    return (
        <Container>
            <PrivateCategorySlider />            
            <Section center >
                <CategoriesItems />
            </Section>
            <Section>
                <h2>
                    Seguir viendo
                    <img src={ArrowImage} alt="Flecha azul" />
                </h2>
                <PrivateSwiper 
                    data={keepWatching}
                    play
                    readyToPlay
                    activeFirstVideo={activeFirstVideo}
                    setActiveFirstVideo={setActiveFirstVideo}
                />
            </Section>
            <Section>
                <h2>Recomendados para ti</h2>
                <PrivateSwiper 
                    data={recommendedCoursesLocal}
                    over={true}
                />
            </Section>
            <Section>
                <h2>
                    Mi lista
                    <img src={ArrowImage} alt="Flecha azul" />
                </h2>
                <PrivateSwiper 
                    data={myListLocal}
                    over={true}
                />
            </Section>
            <Section>
                <h2>Explora a tus expertos favoritos</h2>
                <PrivateSwiper 
                    data={temporaryAuthors}
                    circle
                />
            </Section>
            <Section>
                <h2>Próximamente</h2>
                <PrivateSwiper 
                    data={comingSoonCourses}
                    comingSoon={true}
                />
            </Section>
            <Section>
                <h2>En tendencia</h2>
                <PrivateSwiper
                    data={temporaryTending}
                    over={true}
                />
            </Section>
            <Section>
                <h2>
                    Volver a ver
                    <img src={ArrowImage} alt="Flecha azul" />
                </h2>
                <PrivateSwiper 
                    data={watchingAgain}
                    play
                />
            </Section>
        </Container>
    )
}

export default PrivatedHome