import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from '../pages/home/Home'

// import { useSelector } from 'react-redux'
import Layout from '../layout/Layout'
import Categorias from '../pages/Categorias'
import ProtectedRoute from './ProtectedRoute'
import AuthPage from '../pages/AuthPage'
import PrivateLayout from '../layout/PrivateLayout'
import PrivatedHome from '../pages/private/home/PrivatedHome'
import PrivatedCategories from '../pages/private/categories/PrivatedCategories'
import PrivateCourse from '../pages/private/course/PrivateCourse'
import PrivateChapter from '../pages/private/chapters/PrivateChapter'


import { useSelector } from 'react-redux'
import PrivateAuthor from '../pages/private/author/PrivateAuthor'
import Testimonials from '../pages/testimonials/Testimonials'
import Payment from '../pages/Payment'

const AppRouter = () => {

    const { status, membership } = useSelector(state => state.userInfo)

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Layout />,
            children: [
                {
                    path: '/',
                    element: <Home />
                },
                {
                    path: '/categorias',
                    element: <Categorias />
                },
                {
                    path: '/testimoniales',
                    element: <Testimonials />
                },
                {
                    path: '/payment',
                    element: <Payment />
                }
            ]
        },
        {
            path: '/login',
            element: <AuthPage />
        },
        {
            path: '/register',
            element: <AuthPage />
        },
        {
            path: '/inicio',
            element: 
            <ProtectedRoute
                currentUser={
                     status === 'authenticated'
                    // true     
                }
                membership={membership}
            >
                <PrivateLayout />
            </ProtectedRoute>,
            children: [
                {
                    path: '/inicio',
                    element: <PrivatedHome />
                },
                {
                    path: '/inicio/categorias/:category',
                    element: <PrivatedCategories />
                },
                {
                    path: '/inicio/categorias/:category/:course',
                    element: <PrivateCourse />
                },
                {
                    path: '/inicio/course/:course/video/:videoId',
                    element: <PrivateChapter />
                },
                {
                    path: '/inicio/autor/:id',
                    element: <PrivateAuthor />
                },
            ]
        }
    ])

    return (
        <div>
            <RouterProvider router={router} />
        </div>
    )
}

export default AppRouter