import { useEffect, useState } from 'react'

import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useDispatch, useSelector } from 'react-redux'
import { getCourses } from '../../redux/educationDucks'
import 'swiper/swiper-bundle.css'
import './Instructores.css'

import UserCard from '../card/UserCard'
import CustomButton from '../CustomButton'
import Spinner from '../spinner/Spinner'
import { Link, useNavigate } from 'react-router-dom'

const Instructores = () => {

    const dispatch = useDispatch()
    const { courses, loading } = useSelector(state => state.courses)

    const navigate = useNavigate()

    const [instructors, setInstructors] = useState([])
    const [isDataLoaded, setIsDataLoaded] = useState(false)
     
    useEffect(() => {
        if(!isDataLoaded){
            dispatch(getCourses())
            setIsDataLoaded(true)
        }
    }, [dispatch, isDataLoaded])

    useEffect(() => {
        if(courses.length > 0){
            
            
            const apiInstructors = courses.map((course, i) => {
            console.log(course.author?.id);
                
                let obj = {
                    id: Date.now() + i,
                    img: course.author?.photo,
                    type: course.educationCategory?.name,
                    title: course.title,
                    name: `${course.author?.name} ${course.author?.lastname}`,
                    authorid: course.author?.id
                }
                return obj
            })
           
            setInstructors(apiInstructors)     
            
        }
    }, [courses])

    const handleNavigate = (user) => {
        navigate('/inicio/autor/'+user.authorid)
    }

    return (
        <div className='instructor-container'>
            <h3 className='title container'>Aprende libertad financiera de los mejores</h3>
            <div className='swiper-container'>
                { loading ? 
                        <div style={{display: 'flex', justifyContent: 'center', margin: '30px 0'}} >
                            <Spinner /> 
                        </div>
                    :
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false
                            }}
                            loop
                            pagination={{
                                el: '.pagination',
                                clickable: true
                            }}
                            slidesPerView={5}
                            breakpoints={{
                                "@0.00": {
                                    slidesPerView: 2,
                                    spaceBetween: 25,
                                },
                                "@0.50": {
                                    slidesPerView: 2.25,
                                    spaceBetween: 25,
                                },
                                "@1.00": {
                                    slidesPerView: 2.50,
                                    spaceBetween: 25,
                                },
                                "@1.25": {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                "@1.50": {
                                    slidesPerView: 3.5,
                                    spaceBetween: 30,
                                },
                                "@1.75": {
                                    slidesPerView: 3.75,
                                    spaceBetween: 20,
                                },
                                "@1.85": {
                                    slidesPerView: 4.5,
                                    spaceBetween: 20,
                                },
                            }}
                        >
                            
                            {   
                                

                                instructors.map(user =>
                                    (
                                    
                                    <SwiperSlide
                                        key={user.id}
                                        onClick={() => handleNavigate(user)}
                                        >
                                        <UserCard 
                                            user={user}
                                        />
                                    </SwiperSlide>
                                    )
                                )
                            }
                        </Swiper>
                }
            </div>
            <div className='button-container'>
                <Link to="/categorias">
                    <CustomButton primary>
                        Descubrir cursos
                    </CustomButton>
                </Link>
            </div>  
        </div>
    )
}

export default Instructores