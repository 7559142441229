import axios from "../api/axios"

//url
import { subscriptionUrl, userUrl } from '../api/url'

const initialState = {
    mail: '',
    userId: 0,
    logged: false,
    userName: '',
    userLastName: '',
    profilePhoto: null,
    membership: false,
    userTelephone: '',
    status: 'not-authenticated', //authenticated, checking
    errorMessage: null,
    successMessage: null,
    sevenDayTrial: false,
    dualMembership: false,
    loading: false,
    individual: 'inactive', //'inactive', 'pending', 'paid'
    duo: 'inactive', //'inactive', 'pending', 'paid'
    familiar: 'inactive', //'inactive', 'pending', 'paid'
    // activeInputToken: false,
    verified: null
}

//types
const START_CHECKING = 'START_CHECKING'
const SET_USER_CREATE = 'SET_USER_CREATE'
const SET_USER_LOGIN = 'SET_USER_LOGIN'
const CREATE_USER = 'CREATING_USER'
const LOGOUT = 'LOGOUT'
const CLEAN_MESSAGES = 'CLEAN_MESSAGES'
const LOGIN_USER = 'LOGIN_USER'
const ACTIVATE_SEVEN_DAY_TRIAL = 'ACTIVATE_SEVEN_DAY_TRIAL'
const CLEAN_SEVEN_DAY_TRIAL = 'CLEAN_SEVEN_DAY_TRIAL'
const START_LOADING = 'START_LOADING'
const ACTIVATE_DUALMEMBERSHIP = 'ACTIVATE_DUALMEMBERSHIP'
const CLEAN_DUALMEMBERSHIP = 'CLEAN_DUALMEMBERSHIP'
const CLEAN_STATUS = "CLEAN_STATUS"
const ACTIVE_INPUT_TOKEN = 'ACTIVE_INPUT_TOKEN'
const STOP_LOADING = 'STOP_LOADING'
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
const SET_ACTIVE_CODE = 'SET_ACTIVE_CODE'
const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE'
const STOP_STATUS = 'STOP_STATUS'

// membership
const START_ACTIVATING_INDIVIDUAL = 'START_ACTIVATING_INDIVIDUAL'
const START_ACTIVATING_DUO = 'START_ACTIVATING_DUO'
const START_ACTIVATING_FAMILIAR = 'START_ACTIVATING_FAMILIAR'
const CLEAN_MEMBERSHIPS = 'CLEAN_MEMBERSHIPS'

const ACTIVATE_PLAN = 'ACTIVATE_PLAN'
const ACTIVATE_MEMBERSHIP = 'ACTIVATE_MEMBERSHIP'

//reducer
export default function reducer(state = initialState, action = {}) {
    switch(action.type){

        case START_CHECKING: 
            return {
                ...state,
                status: 'checking'
            }
        case CLEAN_STATUS:
            return {
                ...state,
                status: 'not-authenticated',
                activeInputToken: false,
                userId: 0,
                verified: false,
                mail: ''
            }

        case CREATE_USER:
        case LOGIN_USER:
            return {
                ...state,
                mail: action.payload.mail,
                userId: action.payload.userId,
                userName: action.payload.userName,
                userLastName: action.payload.userLastName,
                profilePhoto: action.payload.photo,
                membership: action.payload.membership,
                userTelephone: action.payload.telephone,
                status: 'authenticated',
                successMessage: action.payload.successMessage,
                logged: true,
                verified: action.payload.verified
            }

        case SET_USER_CREATE:
            return {
                ...state,
                mail: action.payload.email,
                userId: action.payload.id,
                userName: action.payload.name,
                userLastName: action.payload.lastname,
                userTelephone: action.payload.phone,
                status: 'authenticated',
                successMessage: `Usuario creado exitosamente. Por favor ingresa el código que se envió a tu correo ${action.payload.email}`,
                verified: action.payload.verified,
                logged: false,
                membership: action.payload.membership
            }

        case SET_USER_LOGIN:
            return {
                ...state,
                mail: action.payload.email,
                userId: action.payload.id,
                userName: action.payload.name,
                userLastName: action.payload.lastname,
                userTelephone: action.payload.phone,
                status: 'authenticated',
                errorMessage: `Por favor ingresa el código que se envió a tu correo ${action.payload.email}`,
                verified: action.payload.verified,
                logged: false,
                membership: action.payload.membership
            }

        case ACTIVATE_SEVEN_DAY_TRIAL:
            return {
                ...state,
                sevenDayTrial: true,
                loading: false
            }

        case ACTIVATE_MEMBERSHIP:
            return {
                ...state,
                membership: true
            }

        case ACTIVATE_DUALMEMBERSHIP :
            return {
                ...state,
                dualMembership: true,
                loading: false
            }

        case CLEAN_SEVEN_DAY_TRIAL:
            return {    
                ...state,
                sevenDayTrial: false,
                loading: false
            }

        case CLEAN_DUALMEMBERSHIP :
            return {
                ...state,
                dualMembership: false,
                loading: false
            }
        // membership
        case START_ACTIVATING_INDIVIDUAL:
            return {
                ...state,
                individual: 'pending'
            }

        case START_ACTIVATING_DUO:
            return {
                ...state,
                duo: 'pending'
            }

        case START_ACTIVATING_FAMILIAR:
            return {
                ...state,
                familiar: 'pending'
            }

        case STOP_STATUS:
            return {
                ...state,
                status: 'not-authenticated',
                successMessage: null,
                errorMessage: null
            }

        case CLEAN_MEMBERSHIPS:
            return {
                ...state,
                individual: 'inactive',
                duo: 'inactive',
                familiar: 'inactive'
            }
        // TODO: hacerlo con base de datos
        case ACTIVATE_PLAN :
            const { payload } = action
            switch(payload){
                case 'individual':
                    return {
                        ...state, 
                        individual: 'paid'
                    }
                case 'duo':
                    return {
                        ...state,
                        duo: 'paid'
                    }
                case 'familiar':
                    return {
                        ...state,
                        familiar: 'paid'
                    }
                default: 
                    return state
            }

        case ACTIVE_INPUT_TOKEN: 
            return {
                ...state,
                activeInputToken: true
            }
        
        case LOGOUT:
            return {
                ...state,
                mail: '',
                userId: 0,
                logged: false,
                userName: '',
                userLastName: '',
                profilePhoto: null,
                membership: false,
                userTelephone: '',
                status: 'not-authenticated',
                errorMessage: action.payload,
                activeInputToken: false,
                verified: null,
                loading: false
            }
        case CLEAN_MESSAGES:
            return {
                ...state,
                errorMessage: null,
                successMessage: null
            }

        case SET_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload,
                errorMessage: null
            }

        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload,
                verified: false,
                successMessage: null
            }

        case SET_ACTIVE_CODE:
            return {
                ...state,
                errorMessage: null,
                verified: true
            }

        case START_LOADING:
            return {
                ...state,
                loading: true
            }

        case STOP_LOADING:
            return {
                ...state,
                loading: false
            }

        default: 
            return state
    }
}

//actions
export const cleanStatus = () => ({
    type: CLEAN_STATUS
})

export const login = user => {
    return {
        type: LOGIN_USER,
        payload: user
    }
}

export const logout = message => {
    return {
        type: LOGOUT,
        payload: message
    }
}

export const activeInputToken = () => ({
    type: ACTIVE_INPUT_TOKEN,

})

export const creatingUser = ({...rest}) => {
    return async dispatch => {
        dispatch(startChecking())
        try {
            const { data } = await axios.post(userUrl.createWeb, rest)
            console.log(data)
            
            dispatch({
                type: SET_USER_CREATE,
                payload: data.data
            })

            // const obj = {
            //     mail: data.data.email,
            //     userId: data.data.id,
            //     userName: data.data.name,
            //     userLastName: data.data.lastname,
            //     profilePhoto: data.data.photo,
            //     membership: data.data.membership,
            //     telephone: data.data.phone,
            //     successMessage: 'Cuenta creada correctamente'
            // }
            
            // setTimeout(() => {
                // dispatch(login(obj))
                
            // }, 1500)
            
        } catch (error) {
            setTimeout(() => {
                dispatch({
                    type: LOGOUT,
                    payload: error.response.data.message
                })
            }, 1500)
        }
    }
}

export const loginUser = ({...rest}) => {
    return async dispatch => {
        dispatch(startChecking())
        try {
            const { data } = await axios.post(userUrl.loginWeb, rest)
            console.log(data)

            dispatch({
                type: SET_USER_LOGIN,
                payload: data.data
            })

            // const obj = {
            //     mail: data.data.email,
            //     userId: data.data.id,
            //     userName: data.data.name,
            //     userLastName: data.data.lastname,
            //     profilePhoto: data.data.photo,
            //     membership: data.data.membership,
            //     telephone: data.data.phone,
            //     successMessage: 'Inicio de sesión correcto'
            // }
            // if(!data.data.verified) {
            //     const obj = { ...data.data, successMessage: 'Todo correcto, usuario no verificado' }
            //     dispatch(login(obj))
            // }
            // dispatch(login(obj))
            
        } catch (error) {
            setTimeout(() => {
                dispatch({
                    type: LOGOUT,
                    payload: error.response.data.message
                })
            }, 1500)
        }
    }
}

export const sendActivateCode = (obj) => {
    return async dispatch => {
        dispatch({
            type: START_LOADING
        })
        try {
            const { data } = await axios.post(userUrl.verifyTokenWeb, obj)
            if(data.jwt){
                dispatch({
                    type: SET_ACTIVE_CODE
                })

            } else if (data.message){
                dispatch({
                    type: SET_ERROR_MESSAGE,
                    payload: data.message
                })
            } else {
                console.log('Algo pasó')
            }
            dispatch({
                type: STOP_LOADING
            })

        } catch (error) {
            console.log(error)
            dispatch({
                type: STOP_LOADING
            })
        }
    }
}

export const generateNewToken = (userId) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_LOADING
            })

            const { data } = await axios.get(userUrl.generateTokenWeb(userId))
            if(data.message){
                dispatch({
                    type: SET_SUCCESS_MESSAGE,
                    payload: data.message
                })
            }
            dispatch({
                type: STOP_LOADING
            })

        } catch (error) {
            console.log(error);
            dispatch({
                type: STOP_LOADING
            })
        }
    }
}

export const activateSevenDayTrial = () => {
    return dispatch => {
        dispatch({
            type: START_LOADING
        })
        setTimeout(() => {
            dispatch({
                type: ACTIVATE_SEVEN_DAY_TRIAL
            })
        }, 1500)
    }
}

export const cleanSevenDayTrial = () => ({
    type: CLEAN_SEVEN_DAY_TRIAL
})

export const activateDualMembership = () => {
    return dispatch => {
        dispatch({
            type: START_LOADING
        })
        setTimeout(() => {
            dispatch({
                type: ACTIVATE_DUALMEMBERSHIP
            })
        }, 1500)
    }
}

// membership
export const activeIndividual = () => {
    return {
        type: START_ACTIVATING_INDIVIDUAL
    }
}
export const activeDuo = () => {
    return {
        type: START_ACTIVATING_DUO
    }
}
export const activeFamiliar = () => {
    return {
        type: START_ACTIVATING_FAMILIAR
    }
}

export const cleanMemberships = () => ({
    type: CLEAN_MEMBERSHIPS
})

// TODO: hacerlo con la base de datos:
export const activatePlan = plan => {
    return {
        type: ACTIVATE_PLAN,
        payload: plan
    }
}

// TODO: con base de datos
export const activateMembership = () => {
    return async (dispatch, getState) => {
        const { mail } = getState().userInfo
        try {
            const { data } = await axios.put(subscriptionUrl.activateMembership, { email: mail })
            console.log(data)
            dispatch({
                type: ACTIVATE_MEMBERSHIP
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export const cleanDualMembership = () => ({
    type: CLEAN_DUALMEMBERSHIP
})

export const startChecking = () => ({
    type: START_CHECKING
})

export const cleanMessages = () => ({
    type: CLEAN_MESSAGES
})
