const userUrl = {
    create: 'users/',
    login: 'users/auth',
    newToken: 'users/generateToken/',
    verifyToken: 'users/verify',
    passwordRecovery: 'users/password-recovery',
    updateProfilePhoto: 'users/profilephoto/',
    verifyMembership: 'users/membership/',
    changePassword: 'users/change-password/',
    getFaq: 'users/faq',
    exist: 'users/',
    activate: 'users/membership',
    // new rutes
    createWeb: 'users/save-web',
    verifyTokenWeb: 'users/verify',
    generateTokenWeb: userId => `users/generateToken/${userId}`,
    loginWeb: 'users/auth-web'
}

const eduUrl = {
    getCategories: '/education/categories',
    getItems: '/education/',
    getItem: '/education/items/',
    getSuccessCases: '/education/successCases',
    getSuccessCasesTop: '/education/topSuccessCases',
    getGlosario: '/education/glossary',
    getExamQuestios: '/education/examQuestions/',
    getQuestionAnswers: '/education/questionAnswers/',
    createUserItem: '/education/userItem',
    getScore: itemId => `/education/userItem/score/${itemId}`,
    updateScore: '/education/userItem/score',
    getPorcentajes: '/education/porcentajes/',
    // courses,
    getCourseByCategoryId: categoryId => `/courses/course/${categoryId}`,
    

}

const AddUrl = {
    getAdd: '/ads/advertisement/',
    savePrintAdd: '/ads/print',
    saveClickAdd: '/ads/click'
}

// authors
const authorUrl = {
    getAuthor: '/authors/items',
    getAllAuthors: '/authors'
}

// subscriptions
const subscriptionUrl = {
    createSubscription : '/subscription',
    activateMembership: '/users/membership'
}

const videoUrl = {
    getRecentCourses : categoryId => `/video/categories/${categoryId}/recent-courses`,
    getPopularCourse: categoryId => `/video/categories/${categoryId}/top-course`,
    getAuthors: categoryId => `/video/categories/${categoryId}/authors`,
    getCoursesUpComing : categoryId => `/video/categories/${categoryId}/courses-upcoming`,
    getVideoByCourseId : courseId => `/video/courses/${courseId}/video-content`,

    //videocontent
    getVideoByUserAndId : (userId, videoId ) => `/video/users/${userId}/${videoId}/progress`,
    getKeepWatching : (userId ) => `/video/users/${userId}/keepwatching`,

    // cursos favoritos
    
    // home page privated
    getRandomCourses : '/video/random-courses',
    addFavoriteCourses : userId => `/video/users/${userId}/favorite-courses`,
    getFavoriteCourses : userId => `/video/users/${userId}/favorite-courses`,
    getComingSoonCourses: '/video/courses-unavailable',
    getTendingCourses: '/video/random-courses',
    getWatchingAgainCourses: userId => `/video/users/${userId}/watched-videos`,

    // private-course
    getCourseInfo: courseId => `/video/courses/${courseId}`,
    markVideoAsWatched: '/video/users/watched-videos',
    updateProgress: '/video/users/updateprogress',

    // get all Courses
    getAllCoursesByCategory: categoryId => `/video/categories/${categoryId}/courses`,

    // about authors
    getAllCoursesByAuthor: authorId => `/video/authors/${authorId}/courses`,
    getRecenCoursesByAuthor: authorId => `/video/authors/${authorId}/recent-courses`,
    getRandomCourseByAuthor: authorId => `/video/authors/${authorId}/random-course`,
    getAuthorInfo: authorId => `/video/authors/${authorId}`,
    getUnavailableCoursesByAuthor: authorId => `/video/authors/${authorId}/courses/unavailable`
}

const testimonialUrl = {
    getAllTestimonials: '/testimonial',
    createTestimonial: '/testimonial'
}

// get tests
const testsUrl = {
    getTestByCourseIdAndUserId: (courseId) => `/education/exams/${courseId}`,
    getAttempts: (courseId, userId) => `/education/exams/attempts/${courseId}/${userId}`,

    createAttempt: '/education/exams/createAttempt',
}



export { userUrl, eduUrl, AddUrl, authorUrl, subscriptionUrl, videoUrl, testimonialUrl, testsUrl };