import styled from 'styled-components'

export const Container = styled.div`
    background-color: #0B2442;
    padding: 5rem 0;
`

export const Wrapper = styled.div`
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;

    .icon {
        flex: 1;
        img {
            width: 250px;
        }
    }

    .links {
        flex: 1;
        nav {
            display: flex;
            gap: 1rem !important;
            @media (min-width: 768px){
                gap: 8rem;
            }
            a {
                font-size: 16px;
                color: white;
                font-weight: 300;
            }
        }
    }

    .footer__icons {
        flex: 1;
        display: flex;
        gap: 2.5rem;
        img {
            width: 25px;
            height: 25px;
            object-fit: contain;
        }
    }

    .copyright {
        flex: 1;
        p {
            font-size: 13px;
            color: white;
            font-weight: 100;
        }
    }
`