import { useState, useEffect, useRef } from 'react'
import BannerCourse from "../../../components/course/BannerCourse"
import { Button, Container, ErrorContainer, Section } from "./StyledPrivateCourse"
import { useParams } from 'react-router-dom'
import ActionCards from "../../../components/action_cards/ActionCards"
import PrivateSwiper from "../../../components/private-swiper/PrivateSwiper"
import Swal from 'sweetalert2'
import  ProgressBar  from "./ProgressBar"

import { useSelector, useDispatch } from 'react-redux'

// image
import EmptyStar from '../../../assets/curso/iconos/05-estrella-bordes.svg'
import FullStar from '../../../assets/curso/iconos/06-estrella.svg'
import LearningImage from '../../../assets/curso/iconos/07-aprendizaje.svg'
import { addFavoriteCourse, getVideosByCourseId, getCommentsByCourse, getAttemptsByUser } from '../../../redux/educationDucks'
import Spinner from '../../../components/spinner/Spinner'
import Test from '../../../components/test/Test'
import DetailsCourse from '../../../components/details-course/DetailsCourse'


import { updateScore } from '../../../redux/educationDucks'
import { useNavigate } from "react-router-dom";


// share
import {
  FacebookMessengerShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  FacebookMessengerIcon,
  WhatsappIcon,
  TwitterIcon,
  FacebookShareCount
} from "react-share";
import ShareModal from '../../../components/ShareModal'

const PrivateCourse = () => {

  // TODO: 
  // Colocar la variable error correcta
  const { userId } = useSelector(state => state.userInfo)

  const { videos, error, loading, course } = useSelector(state => state.courses)


  const { comments } = useSelector(state => state.courses)


  const { attempts } = useSelector(state => state.courses)




  // share-modal
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)

  const handleShareClick = () => {
    setIsShareModalOpen(true)
  }

  const handleRequestClose = () => {
    setIsShareModalOpen(false)
  }

  useEffect(() => {
    if(!loading){
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [loading, videos])
  
  const [selectedStars, setSelectedStars] = useState(0)
  const [activeFirstVideo, setActiveFirstVideo] = useState(false)

  // 
  const [commentary, setCommentary] = useState('')

  // mostrar capitulos
  const [showChapters, setShowChapters] = useState(true)

  // test
  const [showTest, setShowTest] = useState(false)

  // details
  const [showDetails, setShowDetails] = useState(false)

  const [showResults, setShowResults] = useState(false)

  const { category, course: courseId } = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
      dispatch(getVideosByCourseId(courseId))
  }, [dispatch, courseId])


  useEffect(() => {
    dispatch(getAttemptsByUser(courseId , userId))
}, [dispatch, courseId , userId])


  useEffect(() => {
    dispatch(getCommentsByCourse(courseId))
  }, [dispatch, courseId])


  


  const scores = comments.map(comment => comment.score);

  
  const averageScore = scores.length > 0 ? scores.reduce((total, score) => total + score, 0) / scores.length : 0;


  const commentsLength = comments.length;


 
  const handleClick = item => {
    if(item.title === 'Agregar a lista'){
      dispatch(addFavoriteCourse(courseId))
      Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Curso agregado correctamente',
          showConfirmButton: false,
          timer: 1500
      })
      return
  }
    if(item.title === 'Reproducir') {
        setActiveFirstVideo(true)
    }
    if(item.title === 'Compartir') {
      console.log('Compartiendo...')
      handleShareClick()
    }
  }

  useEffect(() => {
      dispatch(addFavoriteCourse(courseId))
  }, [dispatch, courseId])




  const handleStartTest = () => {
    setShowTest(true)
    window.scrollTo({ top: 600, behavior: 'smooth' });
  }

  const handleCloseTest =  () => {
    setShowTest(false)
     dispatch(getAttemptsByUser(courseId , userId))
     
    window.scrollTo({ top: 600, behavior: 'smooth' })
  }
  
  const handleShowDetails = () => {
    setShowDetails(true)
    setShowChapters(false)
    setShowResults(false)
    window.scrollTo({ top: 600, behavior: 'smooth' })
  }

  const handleShowResults = () => {
    setShowResults(true)
    setShowDetails(false)
    setShowChapters(false)
    window.scrollTo({ top: 600, behavior: 'smooth' })
  }
  
  const handleShowChapters = () => {
    setShowChapters(true)
    setShowDetails(false)
    setShowResults(false)
    window.scrollTo({ top: 600, behavior: 'smooth' })
  }

  const handleSendCommentary = async () => {
    let params = {
      "score":selectedStars,
      "comment":commentary,
      "itemId":courseId,
      "userId": userId
    }
   await dispatch(updateScore({...params, type: 'normal'}))

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: '¡Gracias por tu calificación!',
        showConfirmButton: false,
        timer: 1500
    })
    setCommentary('')
    setSelectedStars(0)
    dispatch(getVideosByCourseId(courseId))
    dispatch(getCommentsByCourse(courseId))
  }
  


  const navigate = useNavigate();


  
  return (
    <Container>
      { loading ? 
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
            <Spinner />
          </div>
          :
          error ? 
          <ErrorContainer>
            <p className='error-msg' >NO HAY INFORMACIÓN PARA ESTE CURSO</p> 
          </ErrorContainer>
          :
          <>
            <BannerCourse 
              category={category}
              course={course}
            />
         {  !showTest && <>
              <ActionCards handleClick={handleClick} />
              <ShareModal 
                isOpen={ isShareModalOpen } 
                onRequestClose={ handleRequestClose }
              />
              <Section>
                <div className="buttons">
                    <Button primary={showChapters ? false : true} onClick={() => handleShowChapters()} >Capítulos</Button>
                    <Button primary={showDetails ? false : true} onClick={() => handleShowDetails()} >Detalles</Button>
                    <Button primary={showResults ? false : true} onClick={() => handleShowResults()} >Evaluaciones</Button>
                </div>
              </Section>
             { showChapters &&
              <>
                <Section>
                  {/* TODO: Descomentar este codigo */}
                  <PrivateSwiper 
                      data={videos}
                      play
                      readyToPlay
                      activeFirstVideo={activeFirstVideo}
                      setActiveFirstVideo={setActiveFirstVideo}
                      
                  />
                </Section>
                <Section className='row m-0'>
                  <div className='col-md-5 row justify-content-center'>
                      <div>
                        <div className='w-100 d-flex justify-content-center'>
                          <h2 className='rating-title'>{averageScore}</h2>
                        </div>
                        
                        <div className='w-100 d-flex justify-content-center'>
                          <span className='rating'>{commentsLength} CALIFICACIONES</span>
                        </div>

                        <div className='w-100 d-flex justify-content-center'>
                          <div className="box-starts row">
                            { [...Array(5)].map((_, index) => (
                              <img 
                                className='mr-4'
                                src={index + 1 <= selectedStars ? FullStar : FullStar}
                                alt={`Estrella ${index}`}
                              />
                            )) }
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className='col-md-6 row line-vertical'>
                    <div className='w-100 d-flex'>
                        <span className='rating'>CALIFICACIONES Y RESEÑAS</span>
                    </div>
                    {/* <div className='w-100 d-flex justify-content-end'>
                      <a className='link-all'>VER TODO</a>
                    </div> */}

                    {
                      comments && 
                    <>
                      {
                      comments.map((comment, index) => (

                        <div className='box-comment row ml-3 mt-3 col-md-12' key={comment.id || index}>
                            <div className='w-100'>
                              <span className='title'> {comment.user.name } {comment.user.lastname}</span>
                            </div>
                            <div className='w-100 d-flex'>
                              <div className="box-starts row m-0">
                                { [...Array(comment.score)].map((_, index) => (
                                  <img 
                                    className='mr-4'
                                    src={index + 1 <= selectedStars ? FullStar : FullStar}
                                    alt={`Estrella ${index}`}
                                  />
                                )) }
                              </div>
                            </div>
                            <div className='w-100 mt-5'>
                              <span className='description'>{comment.comment}</span>
                            </div>
                        </div>
                      ))};
                    </>
                    }
                  </div>
                </Section>
                <Section>
                  <div className="box">
                    <div className="box__comment">
                      <label>Comentar</label>
                      <textarea onChange={e => setCommentary(e.target.value)} value={commentary} />
                    </div>
                    <div className="box__rate" >
                      <p className="box__rate--title">Valorar</p>
                      <div className="box__icons">
                        { [...Array(5)].map((_, index) => (
                          <img 
                            src={index + 1 <= selectedStars ? FullStar : EmptyStar}
                            alt={`Estrella ${index}`}
                            onClick={() => setSelectedStars(index + 1)}
                          />
                        )) }
                      </div>
                      <Button onClick={() => handleSendCommentary()} >Enviar</Button>
                    </div>
                  </div>
                </Section>
              </>
            }
              { showDetails && (
                  <Section center >
                    <DetailsCourse course={course} />
                  </Section>
                ) 
              }

              { showResults && (
                  <Section center className='w-100 row m-0 justify-content-center'>
                    <div className='w-100 justify-content-center d-flex'>
                      <h2>TUS INTENTOS:</h2>
                    </div>
                    {attempts.attemps.map((attemp, index) => (

                      <div className='w-100 col-md-7 mt-3 box-intents row' key={attemp.id || index}>
                        <div className='col-md-4'>
                          <ProgressBar percentage={attemp.score*100/attempts.total} />
                        </div>
                        <div className='col-md-6'>
                          <h4 className='color-blue'>{attempts.title}</h4>
                          <span className='color-blue'>Exámen de conocimientos</span><br/>
                          <span className='color-blue'>Fecha de realización: {attemp.createdAt}</span>
                          <br/>
                        
                        </div>
                        <div className='w-100 row mt-3'>
                            {/* <div className='col-6'>
                              <a className='link-test'>Ver Resultados</a>
                            </div> */}
                            <div className='col-6'>
                              <a className='link-test' onClick={() => handleStartTest()}>Intentar de nuevo</a>
                            </div>

                          </div>

                      </div>
                    ))}

                  </Section>
                ) 
              }
              <Section center>
                <div className="learning" >
                  <h2 className="learning__title">Aprendizaje</h2>
                  <div className="learning__box">
                    <img src={LearningImage} alt="Icono aprendizaje" />
                    <p>
                      Evalúa el conocimiento aprendido con este curso realizando un pequeño cuestionario que comprobará que obtuviste un buen aprendizaje en nuestra plataforma.
                    </p>
                  </div>
                  <Button onClick={() => handleStartTest()} >Comenzar</Button>
                </div>
              </Section>
            </>
          }
            {/* TODO: Eliminar esta seccion */}
            <Section center >
              { showTest && <Test handleCloseTest={handleCloseTest} showTest={showTest} /> }
            </Section>
          </>
      }
      
      
    </Container>
  )
}

export default PrivateCourse