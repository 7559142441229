import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Testimonial.css'
// import Testimonial1 from '../../assets/home/imagenes/08-Testimonial-1.jpg'
// import Testimonial2 from '../../assets/home/imagenes/09-Testimonial-2.jpg'

import { Link } from 'react-router-dom';
import TestimonialItem from '../testimonial_item/TestimonialItem';
import CustomButton from '../CustomButton';

// redux
import { useDispatch, useSelector } from 'react-redux';

import { getAllTestimonials } from '../../redux/testimonialDucks'

// obtener testimoniales
import { useEffect } from 'react'

// spinner
import Spinner from '../spinner/Spinner';


const Testimonial = ({ data }) => {

    const dispatch = useDispatch()
    const { testimonials, loading } = useSelector(state => state.testimonial)

    useEffect(() => {
        dispatch(getAllTestimonials())
    }, [dispatch])

    if(data) {
        console.log('Hay data')
    }

    // const temporalTestimonials = [
    //     {
    //         id: 1,
    //         description: 'Lorem ipsum dolor sit amet, consectetuer adipiscion elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniamlisis',
    //         img: Testimonial1,
    //         name: 'Nombre Apellido',
    //         position: 'Empresario, Guatemala'
    //     },
    //     {
    //         id: 2,
    //         description: 'Lorem ipsum dolor sit amet, consectetuer adipiscion elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniamlisis',
    //         img: Testimonial2,
    //         name: 'Nombre Apellido',
    //         position: 'Empresario, Guatemala'
    //     },
    //     {
    //         id: 3,
    //         description: 'Lorem ipsum dolor sit amet, consectetuer adipiscion elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniamlisis',
    //         img: Testimonial1,
    //         name: 'Nombre Apellido',
    //         position: 'Empresario, Guatemala'
    //     },
    //     {
    //         id: 4,
    //         description: 'Lorem ipsum dolor sit amet, consectetuer adipiscion elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniamlisis',
    //         img: Testimonial2,
    //         name: 'Nombre Apellido',
    //         position: 'Empresario, Guatemala'
    //     },
    // ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <div className='testimonial'>
            <h2 className='testimonial__title'>Inspírate en estas historias</h2>
            { !loading && testimonials.length > 0 ?
            <>
                <Slider
                    { ...settings }
                >
                    { testimonials.slice(0, 4).map(testimonial => (
                        <div key={testimonial.id}>
                            <TestimonialItem 
                                { ...testimonial }
                            />
                        </div>
                    )) }
                </Slider>
                <div className='testimonial__button'>
                    <Link to="/testimoniales">
                        <CustomButton primary >
                            Más casos de éxito
                        </CustomButton>
                    </Link>
                </div>
            </>
              : 
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Spinner />  
              </div>
            }
        </div>
    )
}

export default Testimonial