import { Container, Left, Right, Wrapper } from "./StyledNavbar";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//images
import Logo from "../../assets/home/iconos/00-Logo-menu.svg";
import LupaImage from "../../assets/private-home/iconos/01-buscar.svg";
import UserImage from "../../assets/private-home/iconos/00-user.svg";
import { cleanMemberships, logout } from "../../redux/userDucks";

const PrivateNavbar = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const { userLastName, userName } = useSelector((state) => state.userInfo);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setIsSubMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSubMenuOpen(false);
  };

  const handleSignOut = () => {
    //cerrar sesion
    dispatch(logout("Cerraste sesión"));
    dispatch(cleanMemberships());
    navigate("/login");
  };

  const handleNavigate = () => {
    if (!!userName) {
      navigate("/inicio");
    }
  };

  return (
    <Container>
      <Wrapper>
        <Left onClick={handleNavigate}>
          <img src={Logo} alt="Logo" />
        </Left>{" "}
        <Right>
          <div className="input" style={{ visibility: "hidden" }}>
            <input type="text" />
            <img src={LupaImage} alt="icono lupa" />
          </div>{" "}
          <div className="user" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img src={UserImage} alt="icono user" />{" "}
            {isSubMenuOpen && (
              <div className={`signout-box ${isSubMenuOpen ? "submenu" : ""}`}>
                <p className="username"> {`${userName} ${userLastName}`} </p> <hr />
                <p className="signout" onClick={handleSignOut}>
                  Cerrar sesión{" "}
                </p>{" "}
              </div>
            )}{" "}
          </div>{" "}
        </Right>{" "}
      </Wrapper>{" "}
    </Container>
  );
};

export default PrivateNavbar;
