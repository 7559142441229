import { useEffect, useState } from 'react'

export const useForm = (initialValues = {}, validate, pathname) => {
    const [formValues, setFormValues] = useState(initialValues)
    const [formErrors, setFormErrors] = useState({})
    // const [isSubmitting, setIsSubmitting] = useState(false)

    const handleChange = e => {
        const { name, value } = e.target
        setFormValues({
            ...formValues,
            [name] : value
        })
    }

    useEffect(() => {
        setFormValues(initialValues)
    }, [pathname, initialValues])

    useEffect(() => {
        setFormErrors({})
    }, [pathname])

    const handleSuccess = () => {
        
        const validationErrors = validate(formValues)
        if(pathname === '/login'){
            if(validationErrors.hasOwnProperty('name')){
                delete validationErrors.name
                delete validationErrors.lastname
            }
        }
        setFormErrors(validationErrors)
        return Object.keys(validationErrors).length === 0
    }

    return {
        formValues,
        formErrors,
        // isSubmitting,
        handleChange,
        handleSuccess
    }
}