import axios from '../api/axios'
import { authorUrl, eduUrl, videoUrl, testsUrl } from '../api/url'

const initialState = {
    courses: [],
    categories: [],
    course: {},
    category: {},
    loading: false,
    error: null,
    recentlyAdded: [],
    popularCourse: {},
    coursesUpComing: [],
    authors: [],
    videos: [],
    video:{},
    // private-home
    keepWatching: [],
    recommendedCourses: [],
    myList: [],
    myFavoriteExperts: [],
    comingSoonCourses: [],
    tendingCourses: [],
    watchingAgainCourses: [],
    // private-categories
    topCourse: {},
    authorsByCategory: [],
    comingSoonByCategory: [],
    tendingByCategory: [], 
    comments: [],
    results: []
}

//types
const START_LOADING = 'START_LOADING'
const GET_COURSES_BY_AUTHOR = 'GET_COURSES_BY_AUTHOR'
const GET_COURSES_BY_ID = 'GET_COURSES_BY_ID'
const CLEAN_COURSES = 'CLEAN_COURSES'
const GET_CATEGORIES = 'GET_CATEGORIES'
const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE'
const SELECT_CATEGORY = 'SELECT_CATEGORY'
const GET_VIDEO_BY_COURSEID = 'GET_VIDEO_BY_COURSEID'
const GET_MY_FAVORITE_COURSES = 'GET_MY_FAVORITE_COURSES'
const GET_VIDEO = 'GET_VIDEO'

const GET_COMMENTS = 'GET_COMMENTS'
const GET_ATTEMPTS = 'GET_ATTEMPTS'
// const SET_VIDEO_TO_MYLIST = 'SET_VIDEO_TO_MYLIST'
const SELECT_COURSE = 'SELECT_COURSE'
const UPDATE_SCORE = 'UPDATE_SCORE'
const CREATE_ATTEMPT = 'CREATE_ATTEMPT'

// categories
const FETCH_DATA_BY_CATEGORY_SUCCESS = 'FETCH_DATA_BY_CATEGORY_SUCCESS'

// clean category page
const CLEAN_ALL_CATEGORY_PAGE = 'CLEAN_ALL_CATEGORY_PAGE'


// private
const FETCH_DATA_PRIVATE_SUCCESS = 'FETCH_DATA_PRIVATE_SUCCESS'
const FETCH_DATA_BY_PRIVATE_CATEGORY_SUCCESS = 'FETCH_DATA_BY_PRIVATE_CATEGORY_SUCCESS'

//reducer
export default function reducer(state = initialState, action = {}) {
    switch(action.type){
        case START_LOADING:
            return {
                ...state,
                loading: true
            }

        //courses
        case GET_COURSES_BY_AUTHOR:
            return {
                ...state,
                courses: action.payload,
                loading: false,
                error: null
            }

        case GET_ATTEMPTS:
                return {
                    ...state,
                    attempts: action.payload,
                    loading: false,
                    error: null
                }
        case GET_COURSES_BY_ID: 
            return {
                ...state,
                courses: action.payload,
                loading: false,
                error: null
            }
        case CLEAN_COURSES:
            return {
                ...state,
                courses: [],
                error: null
            }
           
        //categories
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
                courses: [],
                loading: false,
                error: null
            }
        case SELECT_CATEGORY:
            return {
                ...state,
                category: action.payload,
                courses: [],
                error: null,
                loading: false
            }
        case FETCH_ITEMS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case FETCH_DATA_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                recentlyAdded: action.payload.data1,
                popularCourse: action.payload.data2,
                coursesUpComing: action.payload.data4,
                authors: action.payload.data3,
                keepWatching: [],
                recommendedCourses: [],
                myList: [],
                myFavoriteExperts: [],
                comingSoonCourses: [],
                tendingCourses: [],
                watchingAgainCourses: [],
                loading: false,
                error: null
            }

        case FETCH_DATA_PRIVATE_SUCCESS:
            return {
                ...state,
                recommendedCourses: action.payload.data2,
                myList: action.payload.data3,
                myFavoriteExperts: action.payload.data4,
                comingSoonCourses: action.payload.data5,
                tendingCourses: action.payload.data6,
                watchingAgainCourses: action.payload.data7,
                recentlyAdded: [],
                popularCourse: {},
                coursesUpComing: [],
                authors: [],
                loading: false,
                error: null
            }

        case FETCH_DATA_BY_PRIVATE_CATEGORY_SUCCESS:
            return {
                ...state,
                topCourse: action.payload.data1,
                authorsByCategory: action.payload.data2,
                comingSoonByCategory: action.payload.data3,
                tendingByCategory: action.payload.data4,
                loading: false,
                error: null
            }

        case GET_VIDEO_BY_COURSEID:
            return {
                ...state,
                course: action.payload.data1,
                videos: action.payload.data2,
                loading: false,
                error : null
            }

        case GET_MY_FAVORITE_COURSES:
            return {
                ...state,
                myList: action.payload,
                loading: false,
                error: null
            }

        case GET_VIDEO:
                return {
                    ...state,
                    video: action.payload.data.video,
                    loading: false,
                    error: null
                }

        case GET_COMMENTS:
            return {
                ...state,
                comments: action.payload.data.comments,
                loading: false,
                error: null
            }

        case SELECT_COURSE: 
            return {
                ...state,
                course: action.payload,
                error: null,
                loading: false
            }

        // clean category page
        case CLEAN_ALL_CATEGORY_PAGE:
            return {
                ...state,
                category: {},
                recentlyAdded: [],
                popularCourse: {},
                coursesUpComing: [],
                authors: [],
                categories: []
            }

        default: 
            return state
    }
}

//actions
//COURSES
//get all courses by author
export const getCourses = () => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        dispatch({ type: CLEAN_COURSES })
        try {
            const { data } = await axios.get(authorUrl.getAuthor)
            return dispatch({
                type: GET_COURSES_BY_AUTHOR,
                payload: data.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}



export const updateScore = ({...rest}) => {
    return async dispatch => {
        dispatch(startChecking())
        try {
            const { data } = await axios.put(eduUrl.updateScore, rest)
            console.log(data)

            dispatch({
                type: UPDATE_SCORE,
                payload: data.data
            })

            
            
        } catch (error) {
            setTimeout(() => {
                dispatch({
                    type: FETCH_ITEMS_FAILURE,
                    payload: error.response.data.message
                })
            }, 1500)
        }
    }
}



export const createAttempt = ({...rest}) => {
    return async dispatch => {
        dispatch(startChecking())
        try {
            const { data } = await axios.post(testsUrl.createAttempt, rest)
            dispatch({
                type: CREATE_ATTEMPT,
                payload: data.data
            })

        } catch (error) {
            setTimeout(() => {
                dispatch({
                    type: FETCH_ITEMS_FAILURE,
                    payload: error.response.data.message
                })
            }, 1500)
        }
    }
}

export const getAttemptsByUser = (courseId, userId) => {
    return async dispatch => {
        dispatch({type: START_LOADING})
        dispatch({type: CLEAN_COURSES})
        try {
            const { data } = await axios.get(testsUrl.getAttempts(courseId, userId))
            return dispatch({
                type: GET_ATTEMPTS,
                payload: data
            })
        } catch (error) {
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}

export const getCoursesByCategory = categoryId => {
    return async dispatch => {
        dispatch({type: START_LOADING})
        dispatch({type: CLEAN_COURSES})
        try {
            const { data } = await axios.get(eduUrl.getCourseByCategoryId(categoryId))
            return dispatch({
                type: GET_COURSES_BY_ID,
                payload: data.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}

//  CATEGORIES
//get all categories
export const getCategories = () => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const { data } = await axios.get(eduUrl.getCategories)
            return dispatch({
                type: GET_CATEGORIES,
                payload: data
            })
        } catch (error) {
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}

export const selectCategory = (category) => {
    return dispatch => {
        dispatch({type: SELECT_CATEGORY, payload: category})
    }
}

// PAGINA INICIO
export const fetchDataByCategory = (id) => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const [response1, response2, response3, response4] = await Promise.all([
                axios.get(videoUrl.getRecentCourses(id)),
                axios.get(videoUrl.getPopularCourse(id)),
                axios.get(videoUrl.getAuthors(id)),
                axios.get(videoUrl.getCoursesUpComing(id))
            ])
            const data1 = response1.data.data
            const data2 = response2.data.data
            const data3 = response3.data.data
            const data4 = response4.data.data
            // console.log({data1, data2, data3, data4})

            dispatch({
                type: FETCH_DATA_BY_CATEGORY_SUCCESS,
                payload: { data1, data2, data3, data4 }
            })

        } catch (error) {
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}

// PAGINA PRIVADA
export const fetchDataPrivateHome = id => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const [response2, response3, response4, response5, response6, response7] = await Promise.all([
                axios.get(videoUrl.getRandomCourses),
                axios.get(videoUrl.getFavoriteCourses(id)),
                axios.get(authorUrl.getAllAuthors),
                axios.get(videoUrl.getComingSoonCourses),
                axios.get(videoUrl.getTendingCourses),
                axios.get(videoUrl.getWatchingAgainCourses(id))
            ])

            const data2 = response2.data.data
            const data3 = response3.data.data
            const data4 = response4.data.data
            const data5 = response5.data.data
            const data6 = response6.data.data
            const data7 = response7.data.data

            dispatch({
                type: FETCH_DATA_PRIVATE_SUCCESS,
                payload: { data2, data3, data4, data5, data6, data7 }
            })

        } catch (error) {
            console.log(error)
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}

export const getVideosByCourseId = id => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const [response1, response2] = await Promise.all([
                axios.get(videoUrl.getCourseInfo(id)),
                axios.get(videoUrl.getVideoByCourseId(id))
            ])

            const data1 = response1.data.data
            const data2 = response2.data.data

            dispatch({
                type: GET_VIDEO_BY_COURSEID,
                payload: { data1, data2 }
            })
        } catch (error) {
            console.log(error)
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: 'No se pudieron obtener los videos'
            })
        }
    }
}


export const getKeepWatching = (userId) => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const { data } = await axios.get(videoUrl.getKeepWatching(userId))

            dispatch({
                type: GET_VIDEO,
                payload: {data}
            })

            return data;

        } catch (error) {
            console.log(error)
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}

export const getvideoByUserandId = (userId,videoId) => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const { data } = await axios.get(videoUrl.getVideoByUserAndId(userId, videoId))
            console.log("ducks", data);
            dispatch({
                type: GET_VIDEO,
                payload: {data}
            })

            return data;
            
        } catch (error) {
            console.log(error)
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}


export const getCommentsByCourse = (itemId) => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const { data } = await axios.get(eduUrl.getScore(itemId))

            dispatch({
                type: GET_COMMENTS,
                payload: {data}
            })

        } catch (error) {
            console.log(error)
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}


export const fetchDataByPrivateCategory = id => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const [response1, response2, response3, response4] = await Promise.all([
                axios.get(videoUrl.getPopularCourse(id)),
                axios.get(videoUrl.getAuthors(id)),
                axios.get(videoUrl.getCoursesUpComing(id)),
                axios.get(videoUrl.getAllCoursesByCategory(id))
            ])

            const data1 = response1.data.data
            const data2 = response2.data.data
            const data3 = response3.data.data
            const data4 = response4.data.data

            console.log({ data1, data2, data3, data4 })

            dispatch({
                type: FETCH_DATA_BY_PRIVATE_CATEGORY_SUCCESS,
                payload: { data1, data2, data3, data4 }
            })

        } catch (error) {
            console.log(error)
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}
 
// lista de cursos
export const getMyFavoriteCourses = userId => {
    return async dispatch => {
        dispatch({ type: START_LOADING })
        try {
            const { data } = await axios.get(videoUrl.getFavoriteCourses(userId))
            dispatch({
                type: GET_MY_FAVORITE_COURSES,
                payload: data.data
            })

        } catch (error) {
            console.log(error)
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}

// const selectCourse = course => ({
//     type: SELECT_COURSE,
//     payload: course
// })

export const addFavoriteCourse = id => {
    return async (dispatch, getState) => {
        const { userId } = getState().userInfo
        try {
            const { data } = await axios.post(videoUrl.addFavoriteCourses(userId), { educationItemId: id })
            // console.log(data)
        } catch (error) {
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
} 

export const markVideoAsWatched = (id, progress) => {
    return async (dispatch, getState) => {
        const { userId } = getState().userInfo
        try {
            const { data } = await axios.post(videoUrl.markVideoAsWatched, { userId, videoContentId: id, progress: progress})
        } catch (error) {
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}


export const updateProgress = (id, progress) => {
    return async (dispatch, getState) => {
        const { userId } = getState().userInfo
        try {
            const { data } = await axios.post(videoUrl.updateProgress, { userId, videoContentId: id, progress: progress})
        } catch (error) {
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}


export const createLastVisited = (itemId) => {
    return async (dispatch, getState) => {
        const { userId } = getState().userInfo
        try {
            const { data } = await axios.post(eduUrl.createUserItem, { userId, itemId})
        } catch (error) {
            dispatch({
                type: FETCH_ITEMS_FAILURE,
                payload: error.response.data.message
            })
        }
    }
}

// testing skeleton
export const changeSlide = () => {
    return dispatch => {
        dispatch({type: START_LOADING})
        setTimeout(() => {
            dispatch({type:FETCH_ITEMS_FAILURE})
        }, 2300);
    }
}

export const cleanPublicCategoryPage = () => ({
    type: CLEAN_ALL_CATEGORY_PAGE
})


export const startChecking = () => ({
    type: START_LOADING
})