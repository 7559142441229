import styled from 'styled-components'

export const Container = styled.div`
    height: 130px;
    display: flex;
    justify-content: space-between;
    background: rgb(57,157,223);
    background: linear-gradient(90deg, rgba(57,157,223,1) 0%, rgba(87,134,255,1) 100%);
    align-items: center;
    padding: 0 4rem;
    -webkit-box-shadow: 0px 3px 9px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 9px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 9px -4px rgba(0,0,0,0.75);
    position: sticky;
    top: 0;
    z-index: 2;
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export const Left = styled.div`
    &:hover {
        cursor: pointer;
    }
    img {
        width: 250px;
    }
`

export const Right = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    flex: 0 0 300px;
    
    .input {
        display: flex;
        gap: .5rem;
        padding: .5rem 1rem;
        background-color: white;
        border-radius: 20px;
        width: 100%;
        justify-content: space-between;

        input {
            border: none;
            outline: none;
        }

        img {
            width: 15px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    
    .user {
        position: relative;
        img {
            width: 50px;
            transition: all .3s ease;
            &:hover{
                cursor: pointer;
                transform: scale(1.1) rotate(-3deg);
            }
        }

        .signout-box {
            position: absolute;
            bottom: -75px;
            background-color: white;
            padding: 0 1rem;
            border-radius: 3px;
            left: -80px;
            height: 75px;
            width: 150px;

            .username {
                color: black;
                text-align: center;
                margin: 5px 0;
            }

            hr {
                width: 100%;
                background-color: #0B2442;
                height: .5px;
            }

            .signout {
                text-align: center;
                font-weight: 300;
                color: #339DFF;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .submenu {
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-in-out;
        }
        
        &:hover .submenu {
            opacity: 1;
            visibility: visible;
        }
    }
`