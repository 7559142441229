import { Container, Wrapper } from "./StyledActionCards"
import Swal from 'sweetalert2'

import { useDispatch, useSelector } from "react-redux"

// images
import PlayIcon from '../../assets/curso/iconos/01-play.svg'
import ListIcon from '../../assets/curso/iconos/02-lista.svg'
import ShareIcon from '../../assets/curso/iconos/03-compartir.svg'
import DownloadIcon from '../../assets/curso/iconos/04-descargar.svg'

import { addFavoriteCourse } from '../../redux/educationDucks'

const ActionCards = ({ handleClick }) => {

    const dispatch = useDispatch()

    const icons = [
        {
            id: 1,
            title: 'Reproducir',
            img: PlayIcon
        },
        {
            id: 2,
            title: 'Agregar a lista',
            img: ListIcon
        },
        {
            id: 3,
            title: 'Compartir',
            img: ShareIcon
        },
        // {
        //     id: 4,
        //     title: 'Descargar',
        //     img: DownloadIcon
        // },
    ]

    return (
        <Container>
            <Wrapper>
                { icons.map(icon => (
                    <div className="item" key={icon.id} onClick={() => handleClick(icon)} >
                        <img src={icon.img} alt={'imagen icono'} className="item__image" />
                        <p className="item__title" >{icon.title}</p>
                    </div>
                )) }
            </Wrapper>
        </Container>
    )
}

export default ActionCards