import { memo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

const Button = styled.button`

    width: ${props => props.width};
    
    ${props => props.primary && css`
        background: rgb(57,157,223);
        background: linear-gradient(90deg, rgba(57,157,223,1) 0%, rgba(87,134,255,1) 100%);
        border: none;
        color: white;
        padding: .5rem 3rem;
        font-size: 1.3rem;
        -webkit-box-shadow: 0px 0px 17px -5px rgba(87,134,255,1);
        -moz-box-shadow: 0px 0px 17px -5px rgba(87,134,255,1);
        box-shadow: 0px 0px 17px -5px rgba(87,134,255,1);
        font-weight: 400;
        cursor: pointer;
        opacity: ${ props => props.isDisabled ? 0.7 : 1 };
        pointer-events: ${ props => props.isDisabled && 'none' };
    `}
        
    ${props => props.secundary && css`
        background-color: white;
        border: none;
        color: black;
        padding: .5rem 3rem;
        font-size: 1.3rem;
        -webkit-box-shadow: 0px 3px 17px -5px rgba(0,0,0,0.41);
        -moz-box-shadow: 0px 3px 17px -5px rgba(0,0,0,0.41);
        box-shadow: 0px 3px 17px -5px rgba(0,0,0,0.41);
        font-weight: 600;
        cursor: pointer;
    `}

    font-size: ${props => props.fontSize};
    display: ${props => props.invisible ? 'none' : 'block' };
`

const CustomButton = memo(({children, primary = false, width = 'inherit', fontSize, onClickFunction, invisible = false}) => {

    const { status } = useSelector(state => state.userInfo)

  return (
    <>
        {
            primary ? (
                <Button primary width={width} fontSize={fontSize} disabled={status === 'checking'} isDisabled={status === 'checking'} onClick={onClickFunction && onClickFunction} invisible={invisible}>
                    {children}
                </Button>
            ) : (
                <Button secundary width={width} fontSize={fontSize} onClick={onClickFunction && onClickFunction} invisible={invisible}  >
                    {children}
                </Button>
            )
        }
    </>
  )
})

export default CustomButton