import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userDucks';
import educactionReducer from './educationDucks';
import authorReducer from './authorDucks';
import testimonialReducer from './testimonialDucks'
import { combineReducers } from 'redux';
import { 
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
 } from 'redux-persist'
 import storage from 'redux-persist/lib/storage'

 const persistConfig = {
     key: 'root',
     version: 1,
     storage,
 }

 const persistedReducer = persistReducer(persistConfig, combineReducers({
     userInfo: userReducer,
     courses: educactionReducer,
     author: authorReducer,
     testimonial: testimonialReducer
 }))

 export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
 })

 
 export const persistor = persistStore(store)
//  const resetStore = () => {
//      persistor.purge()
//      store.dispatch({ type: 'RESET' })     
//  }

//  resetStore()