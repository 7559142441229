import styled from 'styled-components'

export const Container = styled.div`
    background-color: #0B2442;
    padding-bottom: 5rem;
`

export const Section = styled.section`
    margin: ${props => props.center ? '5rem 15rem' : '4rem auto 4rem 15rem'};
    h2 {
        display: flex;
        font-size: 25px;
        color: white;
        margin: 2rem 0;
        align-items: center;
        gap: 1rem;

        img {
            width: 10px;
            margin-top: 5px;
            &:hover {
                cursor: pointer;
            }
        }
    }
`