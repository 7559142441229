import styled from 'styled-components'

export const Wrapper = styled.div`
    .testimonial-swiper .swiper-button-prev::after {
        content: '';
        background-image: url('/assets/triangle.png');
        display: block;
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transform: rotate(-90deg);
        filter: brightness(0) saturate(100%) invert(51%) sepia(77%) saturate(2441%) hue-rotate(189deg) brightness(102%) contrast(101%);
    }

    .testimonial-swiper .swiper-button-next::after {
        content: '';
        background-image: url('./assets/triangle.png');
        display: block;
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(90deg);
        background-position: center;
        filter: brightness(0) saturate(100%) invert(51%) sepia(77%) saturate(2441%) hue-rotate(189deg) brightness(102%) contrast(101%);
    }
`

export const Container = styled.div`
    width: 100%;
    height: 250px;
    border: ${props => props.active ? '3px solid #339dff' : '3px solid white'};
    border-radius: 20px;
    overflow: hidden;
    transition: all .3s ease;
    background-color: transparent;
    position: relative;

    @media (min-width: 600px) {
        height: 300px
    }

    @media(min-width: 992px){
        height: 400px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .info {
        position: absolute;
        z-index: 9;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 25px;
        text-align: center;
        p {
            color: white;
            font-size: 12px;
            margin: 0;
            text-shadow: 2px 1px 0 black, 1px 1px 1px rgba(206,89,55,0);
            font-weight: 200;
            
            @media (min-width: 600px) {
                font-size: 20px;
            }
            @media (min-width: 992px) {
                font-size: 35px;
            }
        }

        .ocupation {
            font-size: 10px;
            margin: 0;
            text-transform: uppercase;
            text-shadow: 2px 1px 0 black, 1px 1px 1px rgba(206,89,55,0);
            font-weight: 800;
            @media (min-width: 600px) {
                font-size: 14px;
            }
            @media (min-width: 992px) {
                font-size: 16px;
            }
        }
    }

`