import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories, selectCategory } from '../redux/educationDucks'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'

//styles
import 'swiper/css'
import 'swiper/css/pagination'

//images
import ImageTesting from '../assets/categorias/imagenes/00-Deudas-SinUser-1.jpg'
import DeudasImage from '../assets/home/iconos/07-deudas.svg'
import AhorroImage from '../assets/home/iconos/03-ahorro.svg'
import PresupuestoImage from '../assets/home/iconos/04-presupuesto.svg'
import LibertadImage from '../assets/home/iconos/LibertadFinanciera-celeste.svg'
import InversionesImage from '../assets/home/iconos/06-inversiones.svg'
import IngresosImage from '../assets/home/iconos/05-ingresos-egresos.svg'

const Item = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    .principal-image {
        border-radius: 5px;

        @media (min-width: 992px) {
            border-radius: 20px;
        }
    }


    .icon {
        padding: 2px 8px;
        background-color: white;
        position: absolute;
        bottom: -5px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        display: flex;
        gap: .5rem;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 50px;
        height: 15px;

        img {
            width: 10px;
            @media (min-width: 768px) {
                width: 25px;
            }
        }

        p {
            font-size: 5px;
            font-weight: bold;
            margin: 0;
            @media (min-width: 768px) {
                font-size: 10px;
                /* font-weight: 400; */
            }
            @media (min-width: 992px){
                font-size: 14px;
            }
        }

        @media (min-width: 768px) {
            padding: 3px 15px;
            bottom: -5px;
            width: initial;
            height: initial;
        }

        @media (min-width: 992px) {
            padding: 10px 15px;
            bottom: 20px;
        }
    }
    
`

const CategorySlider = ({queryCategory = null}) => {

    const [arrayImages, setArrayImages] = useState([])
    const [initialIndex, setInitialIndex] = useState(3)
    const [isInitialIndexUpdated, setIsInitialIndexUpdated] = useState(false)
    const swiperRef = useRef(null)


    const { categories } = useSelector(state => state.courses)
    const dispatch = useDispatch()
    
    useEffect(() => {
        if(categories.length === 0){
            dispatch(getCategories())
        } else {
            console.log(categories)
            dispatch(selectCategory(categories[0]))
            const newArray = categories.map(category => {
                let icon;
                switch (category.name){
                    case "Ahorro":
                        icon = AhorroImage
                        break;
                    case "Presupuestos":
                        icon = PresupuestoImage
                        break;
                    case "Libertad financiera":
                        icon = LibertadImage
                        break;
                    case "Inversiones":
                        icon = InversionesImage
                        break;
                    case "Deudas":
                        icon = DeudasImage
                        break;
                    case "Ingresos y egresos":
                        icon = IngresosImage
                        break;
                    default :
                        icon = ImageTesting
                }
                return {
                    id: category.id,
                    name: category.name,
                    icon,
                    img: ImageTesting
                }
            })
            setArrayImages(newArray)
        }
    }, [categories, dispatch])

    useEffect(() => {
        if(categories.length > 0 && queryCategory && arrayImages.length > 0) {
            const handleQuerySelect = () => {
                let obj = {}
                switch(queryCategory){  
                    case 'ahorro':
                        setInitialIndex(0)
                        obj = arrayImages.find(el => el.name === 'Ahorro')
                        dispatch(selectCategory(obj))
                        break;
                    case 'presupuestos':
                        console.log(arrayImages)
                        setInitialIndex(1)
                        obj = arrayImages.find(el => el.name === 'Presupuestos')
                        dispatch(selectCategory(obj))
                        break;
                    case 'ingresos y egresos':
                        setInitialIndex(2)
                        obj = arrayImages.find(el => el.name === 'Ingresos y egresos')
                        dispatch(selectCategory(obj))
                        break;
                    case 'inversiones':
                        setInitialIndex(3)
                        obj = arrayImages.find(el => el.name === 'Inversiones')
                        dispatch(selectCategory(obj))
                        break;
                    case 'deudas':
                        console.log('aqui')
                        setInitialIndex(4)
                        obj = arrayImages.find(el => el.name === 'Deudas')
                        dispatch(selectCategory(obj))
                        break;
                    default: 
                        setInitialIndex(0)
                        dispatch(selectCategory(arrayImages.find(el => el.name === 'Ahorro')))
                        break;
                }
            }
            handleQuerySelect()
            setIsInitialIndexUpdated(true)
            if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.slideTo(initialIndex + 3, 0);
            }
        } else {
            setIsInitialIndexUpdated(true)
        }
    }, [queryCategory, categories, arrayImages, dispatch, initialIndex])

    
      

    return (
        <>
           { (queryCategory === null || isInitialIndexUpdated) && (
                <Swiper
                    modules={[Pagination, Navigation]}
                    centeredSlides={true}
                    slidesPerView={3}
                    spaceBetween={30}
                    navigation={true}
                    pagination={{
                        clickable: true
                    }}
                    loop={true}
                    className='swiper-categories-container'
                    initialSlide={initialIndex}
                    ref={swiperRef}
                    onSlideChange={swiper => {

                        let activeIndex = swiper.activeIndex - 3
                        if(activeIndex === -1) {
                            activeIndex = 5;
                        }
                        if(categories.length > 0){
                            dispatch(selectCategory(arrayImages[activeIndex] || arrayImages[0]))
                        }
                    }}
                >
                    {arrayImages.length > 0 && arrayImages.map((item, i) => (
                        <SwiperSlide key={item.id} customprop={'categoria'}>
                            <Item>
                                <img className='principal-image' src={item.img} alt={`id : ${i}`} />
                                <div className='icon'>
                                    <img src={item.icon} alt={`Imagen ${item.name}`} />
                                    <p>{item.name}</p>
                                </div>
                            </Item>
                        </SwiperSlide>
                    ))}   
                </Swiper>
           ) }
        </>
    )
}

export default CategorySlider
