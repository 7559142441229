import styled from 'styled-components'

export const Container = styled.div`
    padding: 15px;
    display: flex;
    justify-content: center;
`

export const Wrapper = styled.div`
    .details {
        font-size: 18px;
        color: white;
        font-weight: 300;
        text-transform: uppercase;
    }
`